import Web3 from "web3";

import erc20Abi from "../../constants/abi/erc20.abi";
import stakingABIContract from "../../constants/abi/staking.json";
import { calculateBalanceSend } from "./utils";
import { BigNumber } from "bignumber.js";
import { defaultChainId, STAKING_CONTRACT_ADDRESS } from "../../_configs";
import { ACTION_STATUS } from "../../constants";
// import exactMath from 'exact-math';

// console.log(BLOCKCHAIN_NETWORK);
export default class Web3Helper {
  constructor(provider, account, chainId) {
    this.web3 = new Web3(provider);
    this.address = account;
    this.chainId = chainId;
  }

  getWeb3Helper(provider, account) {
    if (!Web3Helper.web3) {
      Web3Helper.web3 = new Web3Helper(provider, account);
    }

    return Web3Helper.web3;
  }

  async approve({ tokenContractAddress, contractAddress, amount }, callback) {
    // console.log("amount==>", amount);
    amount = calculateBalanceSend(amount);
    try {
      const tokenContract = this.useERC20Contract(tokenContractAddress);

      callback({
        status: ACTION_STATUS.APPROVING,
      });
      const amountInHex = "0x" + amount.toString(16);
      console.log(amountInHex);
      await tokenContract.methods
        .approve(contractAddress, amountInHex)
        .send({ from: this.address });
      // }
      callback({
        status: ACTION_STATUS.APPROVED,
      });
    } catch (error) {
      callback({
        status: ACTION_STATUS.APPROVE_FAILS,
      });
      console.log(error);
    }
  }

  async getTokenBalance(tokenAddress) {
    const tokenContract = this.useERC20Contract(tokenAddress);
    const tokenBalance = await tokenContract.methods
      .balanceOf(this.address)
      .call();
    // return exactMath.div(tokenBalance, exactMath.pow(10, 18))

    return new BigNumber(tokenBalance.toString())
      .dividedBy(10 ** 18)
      .toFixed(18)
      .replace(/\.?0+$/, "")
      .toString();
  }

  async getAllowance(tokenAddress, contractAddress) {
    const tokenContract = this.useERC20Contract(tokenAddress);

    const allocationNumber = await tokenContract.methods
      .allowance(this.address, contractAddress)
      .call();
    // return exactMath.div(allocationNumber, exactMath.pow(10, 18))
    return new BigNumber(allocationNumber.toString())
      .dividedBy(10 ** 18)
      .toString();
    // return parseFloat(allocationNumber / 10 ** 18);
  }

  async getTokenPadBalance(chainId = defaultChainId) {
    try {
      const contract = this.useStakingContract(chainId);
      const tokenAddress = await contract.methods.token().call();
      return await this.getTokenBalance(tokenAddress);
    } catch (error) {
      console.log(error);
      return 0;
    }
  }

  async stakingDeposit({ amount }, callback) {
    const contract = this.useStakingContract(this.chainId);
    // debugger
    // console.log("amount==>", amount);

    amount = calculateBalanceSend(amount);

    const amountInHex = "0x" + amount.toString(16);
    try {
      const depositResult = await contract.methods
        .stake(amountInHex)
        .send({ from: this.address })
        .on("error", (error) => {
          console.log(error);
          callback({
            status: ACTION_STATUS.STAKING_DEPOSIT_FAIL,
          });
        })
        .on("transactionHash", (hash) => {
          callback({
            status: ACTION_STATUS.STAKING_DEPOSIT_SUBMIT,
            txID: hash,
          });
        })
        .then((receipt) => {
          if (receipt.status === true) {
            callback({
              status: ACTION_STATUS.STAKING_DEPOSIT_SUCCESS,
              txID: receipt.transactionHash,
            });
          } else callback({ status: ACTION_STATUS.STAKING_DEPOSIT_FAIL });
        })
        .catch((err) => {
          console.log(err);
          callback({ status: ACTION_STATUS.STAKING_DEPOSIT_FAIL });
        });
      return depositResult;
    } catch (e) {
      console.error(e.message);
      callback({
        status: ACTION_STATUS.STAKING_DEPOSIT_FAIL,
      });
      return e.message;
    }
  }

  //request withdraw staking
  async stakingInitiateWithdrawal({ amount }, callback) {
    const contract = this.useStakingContract(this.chainId);
    amount = calculateBalanceSend(amount);
    const amountInHex = "0x" + amount.toString(16);
    try {
      const method = contract.methods.unstake(amountInHex);
      await this.web3.eth.call({
        from: this.address,
        to: STAKING_CONTRACT_ADDRESS[this.chainId],
        data: method.encodeABI(),
      });
      const initiateWithdrawalResult = await contract.methods
        .unstake(amountInHex)
        .send({ from: this.address })
        .on("transactionHash", (hash) => {
          callback({
            status: ACTION_STATUS.STAKING_INITIATE_WITHDRAWAL_SUBMIT,
            txID: hash,
          });
        })
        .on("error", (error) => {
          console.log(error);
          callback({
            status: ACTION_STATUS.STAKING_INITIATE_WITHDRAWAL_FAIL,
          });
        })
        .then((receipt) => {
          if (receipt.status === true) {
            callback({
              status: ACTION_STATUS.STAKING_INITIATE_WITHDRAWAL_SUCCESS,
              txID: receipt.transactionHash,
            });
          } else
            callback({
              status: ACTION_STATUS.STAKING_INITIATE_WITHDRAWAL_FAIL,
            });
        })
        .catch((err) => {
          console.log(err);
          callback({ status: ACTION_STATUS.STAKING_INITIATE_WITHDRAWAL_FAIL });
        });
      return initiateWithdrawalResult;
    } catch (e) {
      console.error(e.message);
      callback({
        status: ACTION_STATUS.STAKING_INITIATE_WITHDRAWAL_FAIL,
      });
      return e.message;
    }
  }

  // execute withdraw staking
  async stakingExecuteWithdrawal(callback) {
    const contract = this.useStakingContract(this.chainId);
    const method = contract.methods.withdraw();
    await this.web3.eth.call({
      from: this.address,
      to: STAKING_CONTRACT_ADDRESS[this.chainId],
      data: method.encodeABI(),
    });

    try {
      const executeWithdrawalResult = await contract.methods
        .withdraw()
        .send({ from: this.address })
        .on("transactionHash", (hash) => {
          callback({
            status: ACTION_STATUS.STAKING_EXECUTE_WITHDRAWAL_SUBMIT,
            txID: hash,
          });
        })
        .on("error", (error) => {
          console.log(error);
          callback({
            status: ACTION_STATUS.STAKING_EXECUTE_WITHDRAWAL_FAIL,
          });
        })
        .then((receipt) => {
          if (receipt.status === true) {
            callback({
              status: ACTION_STATUS.STAKING_EXECUTE_WITHDRAWAL_SUCCESS,
              txID: receipt.transactionHash,
            });
          } else
            callback({ status: ACTION_STATUS.STAKING_EXECUTE_WITHDRAWAL_FAIL });
        })
        .catch((err) => {
          console.log(err);
          callback({ status: ACTION_STATUS.STAKING_EXECUTE_WITHDRAWAL_FAIL });
        });
      return executeWithdrawalResult;
    } catch (err) {
      console.log("message==>", err);
      const message = err.message;

      console.log("message==>", message);
      if (err.data) {
        const result = err.data.startsWith("0x") ? err.data : `0x${err.data}`;
        const reason = this.web3.utils.toAscii(`0x${result.substr(138)}`);
        console.log(`Revert reason: ${reason}`);
        callback({
          status: ACTION_STATUS.STAKING_EXECUTE_WITHDRAWAL_FAIL,
          message: reason,
        });
      } else {
        callback({
          status: ACTION_STATUS.STAKING_EXECUTE_WITHDRAWAL_FAIL,
          message: "MESSAGE.ESTIMATE_GAS_FAILS",
        });
      }
      return err.message;
    }
  }

  // execute withdraw rewards
  async stakingExecuteWithdrawRewards(callback) {
    const contract = this.useStakingContract(this.chainId);
    const method = contract.methods.withdrawRewards();
    try {
      await this.web3.eth.call({
        from: this.address,
        to: STAKING_CONTRACT_ADDRESS[this.chainId],
        data: method.encodeABI(),
      });

      contract.methods
        .withdrawRewards()
        .send({ from: this.address })
        .on("transactionHash", (hash) => {
          callback({
            status: ACTION_STATUS.STAKING_EXECUTE_WITHDRAW_REWARDS_SUBMIT,
            txID: hash,
          });
        })
        .on("error", (error) => {
          console.log(error);
          callback({
            status: ACTION_STATUS.STAKING_EXECUTE_WITHDRAW_REWARDS_FAIL,
          });
        })
        .then((receipt) => {
          if (receipt.status === true) {
            callback({
              status: ACTION_STATUS.STAKING_EXECUTE_WITHDRAW_REWARDS_SUCCESS,
              txID: receipt.transactionHash,
            });
          } else
            callback({
              status: ACTION_STATUS.STAKING_EXECUTE_WITHDRAW_REWARDS_FAIL,
            });
        })
        .catch((err) => {
          console.log(err);
          callback({
            status: ACTION_STATUS.STAKING_EXECUTE_WITHDRAW_REWARDS_FAIL,
          });
        });
    } catch (err) {
      const message = err.message;

      console.log("message==>", err);
      if (err.data) {
        const result = err.data.startsWith("0x") ? err.data : `0x${err.data}`;
        const reason = this.web3.utils.toAscii(`0x${result.substr(138)}`);
        console.log(`Revert reason: ${reason}`);
        callback({
          status: ACTION_STATUS.STAKING_EXECUTE_WITHDRAW_REWARDS_FAIL,
          message: reason,
        });
      } else {
        callback({
          status: ACTION_STATUS.STAKING_EXECUTE_WITHDRAW_REWARDS_FAIL,
          message: "MESSAGE.ESTIMATE_GAS_FAILS",
        });
      }
    }
  }

  async stakingRewards(callback) {
    const contract = this.useStakingContract(this.chainId);

    try {
      const method = contract.methods.stakeRewards();

      await this.web3.eth.call({
        from: this.address,
        to: STAKING_CONTRACT_ADDRESS[this.chainId],
        data: method.encodeABI(),
      });
      // debugger
      contract.methods
        .stakeRewards()
        .send({ from: this.address })
        .on("transactionHash", (hash) => {
          callback({
            status: ACTION_STATUS.STAKING_REWARDS_SUBMIT,
            txID: hash,
          });
        })
        .on("error", (error) => {
          console.log(error);
          callback({
            status: ACTION_STATUS.STAKING_REWARDS_FAIL,
          });
        })
        .then((receipt) => {
          if (receipt.status === true) {
            callback({
              status: ACTION_STATUS.STAKING_REWARDS_SUCCESS,
              txID: receipt.transactionHash,
            });
          } else callback({ status: ACTION_STATUS.STAKING_REWARDS_FAIL });
        })
        .catch((err) => {
          console.log(err);
          callback({ status: ACTION_STATUS.STAKING_REWARDS_FAIL });
        });
    } catch (err) {
      const message = err.message;

      console.log("message==>", message);
      if (err.data) {
        const result = err.data.startsWith("0x") ? err.data : `0x${err.data}`;
        const reason = this.web3.utils.toAscii(`0x${result.substr(138)}`);
        console.log(`Revert reason: ${reason}`);
        callback({
          status: ACTION_STATUS.STAKING_REWARDS_FAIL,
          message: reason,
        });
      } else {
        callback({
          status: ACTION_STATUS.STAKING_REWARDS_FAIL,
          message: "MESSAGE.ESTIMATE_GAS_FAILS",
        });
      }
    }
  }

  async getStakingInfoWallet() {
    try {
      const contract = this.useStakingContract(this.chainId);

      const infoWallet = await contract.methods.infoWallet(this.address).call();
      // console.log("infoWallet==>", infoWallet);
      // const infoWalletFees = await contract.methods.infoWalletFees(this.address).call();

      // console.log("rewardAmount==>", BigNumber(infoWallet[5].toString()).dividedBy(10 ** 18).toFixed(18).replace(/\.?0+$/,"").toString());
      
      return {
        stakedAmount: BigNumber(infoWallet[0].toString())
          .dividedBy(10 ** 18)
          .toString(),
        unstakedAmount: BigNumber(infoWallet[1].toString())
          .dividedBy(10 ** 18)
          .toString(),
        depositTimestamp: Number(infoWallet[2]) * 1000,
        lastUnstakeTimestamp: Number(infoWallet[3]) * 1000,
        withdrawTimestamp: Number(infoWallet[4]) * 1000,
        rewardAmount: BigNumber(infoWallet[5].toString())
          .dividedBy(10 ** 18)
          .toFixed(18)
          .replace(/\.?0+$/, "")
          .toString(),
      };
    } catch (error) {
      console.log(error);
      return {};
    }
  }

  useStakingContract(chainId) {
    return new this.web3.eth.Contract(
      stakingABIContract,
      STAKING_CONTRACT_ADDRESS[chainId]
    );
  }

  useERC20Contract(tokenAddress) {
    return new this.web3.eth.Contract(erc20Abi, tokenAddress);
  }
}
