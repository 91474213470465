import React, { useEffect, useState } from "react";
import { useDispatch, } from "react-redux";
import { Link } from "react-router-dom";
import { ACTION_CONST, ROUTES, STAKE_ACTION, STATUS } from "../../constants";
import { BSC_EXPLORER, STAKING_CONTRACT_ADDRESS, BSC_MAT_ADDRESS } from "../../_configs";
import { BigNumber } from "bignumber.js";
import { helpers } from "../../shared/utils";
import StakingPortalPage from "../../shared/layout/StakingPortal";
import { useBnbBalance, usePadTokenBalance, useStakingInfo, useStakingWalletInfo, useWeb3Utils } from "../../hook/useState";
import { useActiveWeb3React } from "../../hook";
import ConfirmationModal from "../../components/ConfirmationModal";

const StakingPage = () => {
    const dispatch = useDispatch();
    const { account, chainId, library } = useActiveWeb3React();
    const walletUtils = useWeb3Utils();
    const stakingInfo = useStakingInfo()
    const stakingWalletInfo = useStakingWalletInfo();
    const bnbBalance = useBnbBalance();
    const matBalance = usePadTokenBalance();
    const [acceptTerm, setAcceptTerm] = useState(false);

    const [swapAmount, setSwapAmount] = useState('')
    const [isMaxAmount, setIsMaxAmount] = useState(false);

    const [swapCurrentStep, setSwapCurrentStep] = useState(1);
    const [enableSwapBtn, setEnableSwapBtn] = useState(false);

    const [transactionHash, setTransactionHash] = useState("")
    const [isSubmitOK, setIsSubmitOK] = useState(false);

    const [approving, setApproving] = useState(false);
    const [loading, setLoading] = useState(0);
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        if (swapCurrentStep === 1) {
            if (acceptTerm &&
                account &&
                !stakingInfo["isStakingPaused"] &&
                parseFloat(matBalance) > 0 &&
                bnbBalance > 0 &&
                stakingWalletInfo["withdrawTimestamp"] === 0
            ) {
                setEnableSwapBtn(true);
            } else {
                setEnableSwapBtn(false);
            }
        }
    }, [acceptTerm, account, bnbBalance, matBalance, stakingInfo, stakingWalletInfo, swapCurrentStep])

    const swapBack = () => {
        if (swapCurrentStep === 1) {
            return;
        } else {
            setSwapCurrentStep(swapCurrentStep - 1);
        }
    }

    const swapNext = async () => {
        if (swapCurrentStep === 5) return;

        if (swapCurrentStep === 1 && parseFloat(swapAmount) === 0) {
            setEnableSwapBtn(false);
            setSwapCurrentStep(swapCurrentStep + 1);
            return;
        }

        if (swapCurrentStep === 3) {
            // click approve
            if (walletUtils) {
                setVisible(true);
                dispatch({
                    type: ACTION_CONST.REQUEST_SUBMIT
                });
                let amount = isMaxAmount ? matBalance : swapAmount
                //check allowance 
                const allowance = await walletUtils.getAllowance(BSC_MAT_ADDRESS[chainId], STAKING_CONTRACT_ADDRESS[chainId]);

                if (BigNumber(allowance).gte(BigNumber(amount))) {
                    setSwapCurrentStep(4);
                    dispatch({
                        type: ACTION_CONST.REQUEST_DONE,
                    });
                    setVisible(false);
                    return;
                }
                setApproving(true);
                walletUtils.approve({ tokenContractAddress: stakingInfo["tokenAddr"], contractAddress: STAKING_CONTRACT_ADDRESS[chainId], amount: amount }, (data) => {
                    //function success
                    if (data.status === STATUS.APPROVED) {
                        setApproving(false);
                        setVisible(false);
                        dispatch({
                            type: ACTION_CONST.REQUEST_DONE
                        });
                        dispatch({
                            type: ACTION_CONST.ALERT_SUCCESS,
                            message: "Approve Tokens successfully!"
                        });
                        setSwapCurrentStep(4);
                    }
                    //function fails
                    if (data.status === STATUS.APPROVE_FAILS) {
                        setApproving(true);
                        setLoading(-1);
                        // setVisible(false);
                        dispatch({
                            type: ACTION_CONST.REQUEST_DONE
                        })
                        dispatch({
                            type: ACTION_CONST.ALERT_FAILS,
                            message: "Failed to Approve Tokens!"
                        })
                    }
                })
            }
        } else if (swapCurrentStep === 4) {
            // click deposit
            if (walletUtils) {
                setVisible(true);
                dispatch({
                    type: ACTION_CONST.REQUEST_SUBMIT
                })
                walletUtils.stakingDeposit({ amount: isMaxAmount ? matBalance : swapAmount }, (result) => {
                    if (result.status === STATUS.STAKING_DEPOSIT_SUCCESS) {
                        setLoading(1);
                        setVisible(false);
                        dispatch({
                            type: ACTION_CONST.REQUEST_DONE
                        })
                        setIsSubmitOK(true)
                        setTransactionHash(result.txID)

                        //change to result screen
                        setSwapCurrentStep(5);

                    }
                    if (result.status === STATUS.STAKING_DEPOSIT_FAIL) {
                        setLoading(-1);
                        setAcceptTerm(false);
                        // setVisible(false);
                        dispatch({
                            type: ACTION_CONST.REQUEST_DONE
                        })
                        dispatch({
                            type: ACTION_CONST.ALERT_FAILS,
                            message: "Deposit stake fail!"
                        })
                    }
                })
            }
        } else {
            setSwapCurrentStep(swapCurrentStep + 1);
        }
    }


    //handle input swap change
    const handleInputSwap = (e) => {
        setIsMaxAmount(false);

        if (helpers.isFloatFormatted(e.target.value, 4)) {
            setSwapAmount(e.target.value);

            //check input
            if (BigNumber(e.target.value).lte(BigNumber(matBalance)) && BigNumber(e.target.value).gt(BigNumber(0))) {
                setEnableSwapBtn(true);
            } else {
                setEnableSwapBtn(false);
            }
        }
    }

    const submitDone = () => {
        setSwapAmount('');
        setIsMaxAmount(true);
        setSwapCurrentStep(1);
        setIsSubmitOK(false);
        setAcceptTerm(false);
        setLoading(0);
        setApproving(false);
    }

    const handleMaxButtonClick = () => {
        setSwapAmount(helpers.formatNumberDownRoundInput(matBalance, 4).toString());
        setEnableSwapBtn(true);
        setIsMaxAmount(true);
    }

    return (
        <StakingPortalPage selectedTab={1}>
            <div className="tab-pane fade show active" id="stake" role="tabpanel" aria-labelledby="stake-tab">
                <div className="row justify-content-center mb-4">
                    <div className="col-lg-12">
                        <div className="bs-stepper w-100">
                            <div className="bs-stepper-header" role="tablist">
                                <div className={`step ${swapCurrentStep >= 1 ? 'active' : ''}`} id="swapButtonStep1">
                                    <button type="button" className="step-trigger ps-0">
                                        <img src="/images/cir.png" />
                                        <span className="bs-stepper-label first">Checkpoints</span>
                                    </button>
                                </div>
                                <div className={`line ${swapCurrentStep > 1 ? 'active' : ''}`} id="swapLineStep1" />
                                <div className={`step ${swapCurrentStep >= 2 ? 'active' : ''}`} id="swapButtonStep2">
                                    <button type="button" className="step-trigger">
                                        <img src="/images/cir.png" />
                                        <span className="bs-stepper-label">Amount Stake</span>
                                    </button>
                                </div>
                                <div className={`line ${swapCurrentStep > 2 ? 'active' : ''}`} id="swapLineStep2" />
                                <div className={`step ${swapCurrentStep >= 3 ? 'active' : ''}`} id="swapButtonStep3">
                                    <button type="button" className="step-trigger">
                                        <img src="/images/cir.png" />
                                        <span className="bs-stepper-label">Pre-authorization</span>
                                    </button>
                                </div>
                                <div className={`line ${swapCurrentStep > 3 ? 'active' : ''}`} id="swapLineStep3" />
                                <div className={`step ${swapCurrentStep >= 4 ? 'active' : ''}`} id="swapButtonStep4">
                                    <button type="button" className="step-trigger">
                                        <img src="/images/cir.png" />
                                        <span className="bs-stepper-label">Confirm</span>
                                    </button>
                                </div>
                                <div className="line" id="swapLineStep4" />
                                <div className={`step ${swapCurrentStep >= 5 ? 'active' : ''}`} id="swapButtonStep5">
                                    <button type="button" className="step-trigger">
                                        <img src="/images/cir.png" />
                                        <span className="bs-stepper-label last">Confirmation</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-lg-12">
                        <div className="">
                            <div className="p-sidebar-card-body">
                                {swapCurrentStep === 1 &&
                                    <div className="bs-stepper-content" id="SwapStep1">
                                        <div className="text-center mb-5">
                                            <h4 className="mb-2 pb-1 step-title">Checkpoints</h4>
                                            <p className="step-desc">The following conditions must be met to proceed:</p>
                                        </div>

                                        <div className="grant__bordered bordered mb-0">
                                            <div className="bordered__inner bordered-statistics bordered--flex bordered--tripled bordered--cust bordered--double_angled bordered--bg_bottom">
                                                <div className={`bordered__block d-flex-1 ${account ? 'selected' : ''}`} >
                                                    <div className="p-sidebar-card-title">Connected MetaMask</div>
                                                    <div className="p-sidebar-card-body mt-2">
                                                        If not connected, click the "Connect Wallet" button in the top right corner
                                                    </div>
                                                </div>
                                                <div className={`bordered__block d-flex-1 ${parseFloat(matBalance) > 0 ? 'selected' : ''}`} >
                                                    <div className="p-sidebar-card-title">MAT available</div>
                                                    <div className="p-sidebar-card-body mt-2">
                                                        Current Balance: {helpers.formatNumberDownRoundWithExtractMax(matBalance, 4)}
                                                    </div>
                                                </div>
                                                <div className={`bordered__block d-flex-1 ${bnbBalance > 0 ? 'selected' : ''}`} >
                                                    <div className="p-sidebar-card-title">BNB available</div>
                                                    <div className="p-sidebar-card-body mt-2">
                                                        BNB is required to pay transaction fees on the Binance Smart Chain network.<br />
                                                        BNB Balance: {helpers.formatNumberDownRoundWithExtractMax(bnbBalance, 4)}
                                                    </div>
                                                </div>
                                                <div className={`bordered__block d-flex-1 last ${stakingWalletInfo["withdrawTimestamp"] === 0 ? 'selected' : ''}`} >
                                                    <div className="p-sidebar-card-title">Eligible to stake</div>
                                                    <div className="p-sidebar-card-body mt-2">
                                                        You cannot stake if you have an active MAT unstake/withdrawal request
                                                    </div>
                                                </div>
                                                <div className="bordered__border bordered__border--left"></div>
                                                <div className="bordered__border bordered__border--right"></div>
                                            </div>
                                            <div className="bordered__icon bordered__icon--top_left bordered__icon--horizont bordered__icon--horizont_top">
                                                <img src="/images/bordered-lines.svg" alt="" />
                                            </div>
                                        </div>

                                        <div className="mt-4 mb-3 text-center">
                                            <div className="form-check">
                                                <input className="form-check-input float-none me-1"
                                                    type="checkbox" id="flexCheckDefault" checked={acceptTerm}
                                                    onChange={() => setAcceptTerm(prev => !prev)} />
                                                <label className="form-check-label" htmlFor="flexCheckDefault">
                                                    I have read the&nbsp;
                                                    <Link target="_blank" to={ROUTES.TERM_OF_USES}>Terms and Conditions </Link>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                }

                                {swapCurrentStep === 2 &&
                                    <div className="bs-stepper-content" id="SwapStep2">
                                        <div className="text-center">
                                            <h4 className="mb-2 pb-1 step-title">Amount Stake</h4>
                                            <p className="step-desc text-white">Please enter the amount of MAT you want to stake</p>
                                            <p className="step-desc">Any fees applied at Unstake &amp; Withdraw stage will be based on the date you last staked.</p>
                                            <div className="unlock__bordered bordered mx-auto mt-5" style={{ maxWidth: '100%', width: '400px' }}>
                                                <div className="bordered__inner bordered--flex bordered--double_angled bordered--bg_right">
                                                    <div className="bordered__block pb-3">
                                                        <div className="text-start">
                                                            <div className="p-form-group">
                                                                <label className="form-label p-main-text">Amount</label>
                                                                <div className="btn btn--custom bordered bordered--btn bordered--btn_no-angled position-relative w-100 py-0">
                                                                    <div className="d-flex align-items-center justify-content-between px-3 w-100">
                                                                        <input autoFocus className="form-control px-0" placeholder={0.0} value={swapAmount} onChange={(e) => handleInputSwap(e)} />
                                                                        <span style={{ cursor: 'pointer' }} onClick={handleMaxButtonClick}>MAX</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="d-flex align-items-start justify-content-between">
                                                                <div className="font-16">Balance: <b className="text-warning">{helpers.formatNumberDownRound(matBalance, 4)}</b></div>
                                                                <div />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="bordered__border bordered__border--left"></div>
                                                    <div className="bordered__border bordered__border--right"></div>
                                                </div>
                                                <div className="bordered__icon bordered__icon--top_left bordered__icon--horizont bordered__icon--horizont_top">
                                                    <img src="/images/bordered-lines.svg" alt="" />
                                                </div>
                                                <div className="bordered__icon bordered__icon--bottom_center bordered__icon--horizont bordered__icon--horizont_bottom">
                                                    <img src="/images/bordered-lines.svg" alt="" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }

                                {swapCurrentStep === 3 &&
                                    <div className="bs-stepper-content" id="SwapStep3">
                                        <div className="text-center">
                                            <h4 className="mb-2 pb-1 step-title">Pre-authorization</h4>
                                            <p className="step-desc">Required transaction 1 of 2</p>
                                            <p className="step-desc">In this step, you grant access to the staking smart contract to accept your MAT</p>
                                        </div>
                                        <div className="text-center mt-4">
                                            <div className="p-progress-waiting">
                                                <div className="p-progress-percent" style={{ width: '60%' }} />
                                            </div>
                                            <h4 className="mb-2 pb-1 mt-5">Waiting for the transaction to complete</h4>
                                            <p>Please wait for the transaction to confirm before proceeding.</p>
                                            <p><a className="p-address" href={`${BSC_EXPLORER[chainId]}/address/${account}`} target="_blank">{account}</a></p>
                                        </div>
                                    </div>
                                }

                                {swapCurrentStep === 4 &&
                                    <div className="bs-stepper-content" id="SwapStep4">
                                        <div className="text-center">
                                            <h4 className="mb-2 pb-1 step-title">Confirm</h4>
                                            <p className="step-desc">Required transaction 2 of 2</p>
                                            <p className="step-desc">
                                                In this step, you deposit the tokens into the staking contract.<br />
                                                After this step, your tokens will be successfully staked.
                                            </p>
                                        </div>
                                    </div>
                                }

                                {swapCurrentStep === 5 &&
                                    <div className="bs-stepper-content" id="SwapStep5">
                                        <div className="text-center">
                                            <div className="icon-gradient">
                                                <i className="mdi mdi-shield-check-outline" style={{ fontSize: '70px', lineHeight: 1.3 }} />
                                            </div>
                                            <h4 className="step-title">Success</h4>
                                            <p className="step-desc">
                                                Congratulations! Your tokens are now staked.<br />
                                                If desired, you may check Binance Smart Chain to confirm the transaction.
                                            </p>
                                            <p><a className="p-address" href={`${BSC_EXPLORER[chainId]}/tx/${transactionHash}`} target="blank">{transactionHash}</a></p>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mb-5 text-center step-buttons" style={{ height: '100px', paddingTop: '50px' }}>
                    {
                        !isSubmitOK ?
                            <div className="d-flex align-items-center justify-content-center" style={{ gap: '20px' }}>
                                <button type="button"
                                    onClick={() => swapBack()} type="button"
                                    disabled={!enableSwapBtn || swapCurrentStep === 1}
                                    className="tq_btn tq_btn_sm tq_btn_outline tq_btn_outline_primary px-4 px-md-5">
                                    <span className="tq_btn_text">
                                        <i className="mdi mdi-arrow-left me-2"></i>
                                        <span>Previous</span>
                                    </span>
                                    <div className="tq_btn_hover"></div>
                                </button>

                                <button type="button"
                                   onClick={() => swapNext()} type="button"
                                   disabled={!enableSwapBtn || swapCurrentStep === 5}
                                    className="tq_btn tq_btn_sm tq_btn_outline tq_btn_outline_primary tq_btn_primary px-4 px-md-5">
                                    <span className="tq_btn_text">
                                        <span>Next</span>
                                        <i className="mdi mdi-arrow-right ms-2"></i>
                                    </span>
                                    <div className="tq_btn_hover"></div>
                                </button>
                            </div>
                            :
                            <div className="d-flex align-items-center justify-content-center">
                                <button type="button"
                                    onClick={() => submitDone()}
                                    className="tq_btn tq_btn_sm tq_btn_outline tq_btn_outline_primary tq_btn_primary px-4 px-md-5">
                                    <span className="tq_btn_text">
                                        <span>Done</span>
                                    </span>
                                    <div className="tq_btn_hover"></div>
                                </button>
                            </div>
                    }
                </div>
            </div>
            <ConfirmationModal
                visible={visible}
                loading={loading}
                approving={approving}
                type={STAKE_ACTION.STAKING}
                onCancel={() => {
                    setVisible(false);
                    setLoading(0);
                    setApproving(false);
                }}
            />
        </StakingPortalPage>
    );
};

export default StakingPage;
