import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { ACTION_CONST, STAKE_ACTION, STATUS } from "../../constants";
import { getCountDown } from "../utils/helper";

import { helpers } from "../utils";
import { useActiveWeb3React } from "../../hook";
import { useLatestBlockNumber, useStakeAmount, useStakingWalletInfo, useSubmit, useWeb3Utils } from "../../hook/useState";
import Countdown, { CountdownApi } from 'react-countdown';
import ConfirmationModal from "../../components/ConfirmationModal";
import { COUNTDOWN_TIME } from "../../_configs";

const RightBar = () => {

  const dispatch = useDispatch();
  const { chainId, account } = useActiveWeb3React();
  const walletUtils = useWeb3Utils()
  const [stakedAmount, setStakedAmount] = useState('0');
  const [unstakedAmount, setUnstakedAmount] = useState('0');
  const [rewardAmount, setRewardAmount] = useState('0');
  const [withdrawTimestamp, setWithdrawTimestamp] = useState(0);
  const submitOK = useSubmit();
  const latestBlock = useLatestBlockNumber();

  const [txID, setTxID] = useState('');

  const [stakeLoading, setStakeLoading] = useState(0);
  const [stakeVisible, setStakeVisible] = useState(false);

  const [withdrawLoading, setWithdrawLoading] = useState(0);
  const [withdrawVisible, setWithdrawVisible] = useState(false);
  // // countdown
  // const [showCountDown, setShowCountDown] = useState(false);

  // useEffect(() => {
  //   if (new Date().getTime() > COUNTDOWN_TIME) {
  //     setShowCountDown(false);
  //   } else {
  //     setShowCountDown(true);
  //   }
  // }, []);

  useEffect(() => {
    if (walletUtils && account) {
      walletUtils.getStakingInfoWallet().then(data => {
        setStakedAmount(data.stakedAmount);
        setUnstakedAmount(data.unstakedAmount);
        setRewardAmount(Number(data.rewardAmount));
        const now = new Date().getTime();
        if (data.withdrawTimestamp > now) {
          setWithdrawTimestamp(data.withdrawTimestamp);
        }
        // else{
        //   setWithdrawTimestamp(0);
        // }
        dispatch({ type: ACTION_CONST.GET_STAKING_WALLET_INFO, data: data })
      });
    }

  }, [walletUtils, account, submitOK, dispatch, withdrawTimestamp, latestBlock])


  //handle button withdraw click 
  const handleExcWithDraw = () => {
    setWithdrawLoading(0);
    if (walletUtils) {
      setWithdrawVisible(true);
      dispatch({
        type: ACTION_CONST.REQUEST_SUBMIT
      })
      walletUtils.stakingExecuteWithdrawRewards(data => {
        if (data.status === STATUS.STAKING_EXECUTE_WITHDRAW_REWARDS_SUCCESS) {
          setWithdrawLoading(1);
          setTxID(data.txID);
          dispatch({
            type: ACTION_CONST.REQUEST_DONE
          })
          dispatch({
            type: ACTION_CONST.ALERT_SUCCESS,
            message: "Withdraw stake successfully"
          })

        }
        if (data.status === STATUS.STAKING_EXECUTE_WITHDRAW_REWARDS_FAIL) {
          setWithdrawLoading(-1);
          dispatch({
            type: ACTION_CONST.REQUEST_DONE
          })
          dispatch({
            type: ACTION_CONST.ALERT_FAILS,
            message: "Withdraw stake fail"
          })
        }
      })
    }
    //to do somrthing
  }

  //stake reward
  const handleExStakeRewards = async () => {
    setStakeLoading(0);
    setTxID('');
    if (walletUtils) {
      setStakeVisible(true);
      dispatch({
        type: ACTION_CONST.REQUEST_SUBMIT
      })
      walletUtils.stakingRewards(data => {
        // console.log('data', data);
        if (data.status === STATUS.STAKING_REWARDS_SUCCESS) {
          setStakeLoading(1);
          setTxID(data.txID);
          dispatch({
            type: ACTION_CONST.REQUEST_DONE
          })
          dispatch({
            type: ACTION_CONST.ALERT_SUCCESS,
            message: "Stake rewards successfully"
          })

        }
        if (data.status === STATUS.STAKING_REWARDS_FAIL) {
          setStakeLoading(-1);
          dispatch({
            type: ACTION_CONST.REQUEST_DONE
          })
          dispatch({
            type: ACTION_CONST.ALERT_FAILS,
            message: "Stake rewards fail"
          })
        }
      })
    }
  }

  // // countdown onCompleted
  // const onComplete = (val) => {
  //   setShowCountDown(false);
  // }

  return (
    <>
      <div className="p-sidebar">
        <div className="mb-3 mt-3 w-100">
          <div className="row">
            {/* {showCountDown &&
              <div className="col-lg-12 col-md-6">
                <div className="detail-card sale-info-card mb-5">
                  <div className="detail-card-title">
                    <span>Countdown</span>
                  </div>
                  <div className="detail-card-body">
                    {showCountDown && (
                      <div>
                        <div className="mt-3 mb-2 detail-card-value">
                          <Countdown
                            date={new Date(COUNTDOWN_TIME)}
                            intervalDelay={1}
                            precision={3}
                            renderer={renderCountDownOpen}
                            onComplete={onComplete}
                          />
                          <h6>Staking available in</h6>
                        </div>
                      </div>
                    )}
                    {parseFloat(unstakedAmount) > 0 && withdrawTimestamp === 0 && (<div>Withdrawable Now</div>)}
                  </div>
                </div>
              </div>
            } */}
            <div className="col-lg-12 col-md-6">
              <div className="detail-card sale-info-card mb-5">
                <div className="detail-card-title">
                  <span>Staked</span>
                </div>
                <div className="detail-card-body">
                  <h3 className="mt-3 mb-2 detail-card-value">{helpers.formatNumberDownRoundWithExtractMax(stakedAmount, 4)}</h3>
                </div>
              </div>
            </div>
            <div className="col-lg-12 col-md-6">
              <div className="detail-card sale-info-card mb-5">
                <div className="detail-card-title">
                  <span>Unstake</span>
                </div>
                <div className="detail-card-body">
                  <h3 className="mt-3 mb-2 detail-card-value">{helpers.formatNumberDownRoundWithExtractMax(unstakedAmount, 4)}</h3>
                  {parseFloat(unstakedAmount) > 0 && withdrawTimestamp > 0 && (
                    <div>
                      Withdrawable in:
                      <Countdown
                        date={new Date(withdrawTimestamp)}
                        intervalDelay={1}
                        precision={3}
                        renderer={renderCountDownWithDraw}
                      />
                    </div>
                  )}
                  {parseFloat(unstakedAmount) > 0 && withdrawTimestamp === 0 && (<div>Withdrawable Now</div>)}
                </div>
              </div>
            </div>
            <div className="col-lg-12 col-md-6">
              <div className="detail-card sale-info-card">
                <div className="detail-card-title">
                  <span>Rewards</span>
                </div>

                <div className="detail-card-body">
                  <h3 className="mt-3 mb-2 detail-card-value">{helpers.formatNumberDownRoundWithExtractMax(rewardAmount, 4)}</h3>
                  <div className="mt-3 mb-2 d-block w-100">
                    <button type="button"
                      disabled={!account || parseFloat(rewardAmount) === 0 || parseFloat(unstakedAmount) > 0}
                      onClick={() => handleExStakeRewards()}
                      className="tq_btn tq_btn_outline tq_btn_outline_primary tq_btn_primary w-100 mb-3">
                      <span className="tq_btn_text">Stake</span>
                      <div className="tq_btn_hover"></div>
                    </button>



                    <button type="button"
                      disabled={!account || parseFloat(rewardAmount) === 0 || parseFloat(unstakedAmount) > 0}
                      onClick={() => handleExcWithDraw()}
                      className="tq_btn tq_btn_outline tq_btn_outline_danger w-100">
                      <span className="tq_btn_text">Withdraw</span>
                      <div className="tq_btn_hover"></div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="d-xl-block d-none mt-3">
          <img src="/images/banner.png" className="w-100" />
        </div> */}
      </div>
      <div className="p-sidebar-backdrop"></div>
      <ConfirmationModal
        visible={stakeVisible}
        loading={stakeLoading}
        type={STAKE_ACTION.STAKING}
        txID={txID}
        onCancel={() => {
          setTxID('');
          setStakeVisible(false);
          setStakeLoading(0);
        }}
      />
      <ConfirmationModal
        visible={withdrawVisible}
        loading={withdrawLoading}
        type={STAKE_ACTION.WITHDRAW}
        txID={txID}
        onCancel={() => {
          setTxID('');
          setWithdrawVisible(false);
          setWithdrawLoading(0);
        }}
      />
    </>
  );
};

export default RightBar;


const renderCountDownWithDraw = ({ days, hours, minutes, seconds, completed }) => {
  if (completed) {
    // Render a completed state
    return <h4>0h 0m 0s</h4>;
  } else {
    // Render a countdown
    return <h4 className="d-block w-100 overflow-hidden">{days}d {hours}h {minutes}m {seconds}s</h4>;
  }
}

const renderCountDownOpen = ({ days, hours, minutes, seconds, completed }) => {
  if (completed) {
    // Render a completed state
    return <h4>0h 0m 0s</h4>;
  } else {
    // Render a countdown
    return <h4 className="d-block w-100 overflow-hidden countdown">{hours}h {minutes}m {seconds}s</h4>;
  }
}