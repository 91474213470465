import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import Footer from "../../shared/components/Footer";
import * as $ from 'jquery';

const TermOfUsePage = () => {
    useEffect(() => {
        $('body').addClass('no-bg');
        return (() => {
            $('body').removeClass('no-bg');
        })
    }, [])

    return <>
        <Container>
            <div className="w-100 term-page" style={{ minHeight: 'calc(100vh - 168px)' }}>
                <div className="py-5 text-white">
                    <div className="text-center mb-5">
                        <h2 style={{ fontSize: '50px' }}><b>MYMASTERWAR LAB. LTD</b></h2>
                        <h5><b>Terms and Conditions of <a href="https://Mymasterwar.com/" target="_blank">https://mymasterwar.com</a></b></h5>
                        <div>Last revised Date 6<sup>th</sup> November 2021<sup>[1]</sup></div>
                    </div>
                    <div className="term-page-content" style={{ fontSize: '16px' }}>
                        <h5>1. Introduction</h5>
                        <p>These Terms and Conditions (herein referred as “<b>Terms</b>”) govern the use and the conditions of <a href="https://mymasterwar.com/" target="_blank">https://mymasterwar.com/</a> (herein referred as “<b>Website</b>”), and the Services provided by MyMasterWar Lab Limited(herein referred as “<b>Company</b>” or “<b>We</b>” or “<b>Us</b>”), a company incorporated and registered under the laws of England and Wales. These Terms constitute a binding and enforceable legal contract between the Company and its Affiliates and subsidiaries worldwide and you, an end user of the Services (herein referred as “<b>You</b>” or “<b>User</b>”) in relation to the Services. You and the Company are referred to separately as “<b>Party</b>” and collectively as “<b>Parties</b>”.</p>
                        <p>By accessing, registering, unlocking, using, or clicking on the Services, and information made available by the Company via the Website you hereby accept and agree to all the Terms set forth herein.</p>
                        <p>You are strongly advised to carefully read these Terms as well as the provisions detailed in our Privacy Policy prior to using the Website and our Services. By using the Website and the Services in any capacity, you agree that: (i) You have read and familiarized yourself with these Terms; (ii) You understand these Terms; and (iii) You agree to be bound by these Terms when using the Website. If You do not agree to these Terms, please do not access or use the Website and the Services.</p>
                        <p>We reserve the right to modify or amend these Terms, the Website, or any content on either one of the platforms from time to time, including for security, legal, or regulatory reasons, as well as to reflect updates or changes to the Services or functionality of the Website. You are advised to check these Terms periodically to ensure that you are cognizant of the current versions and comply with them. Users of the Website and the Services are bound by these changes which will take immediate effect after the revised versions of these Terms have been published on the Website or mobile application. Through your continued use of or interaction with the Website, the Services, tools, and information made available on these platforms, you hereby agree to be bound by the provisions highlighted in the subsequent versions.</p>
                        <p>We will provide a notification on the Website specifying that changes have been made to these Terms whenever they occur. By accepting the notification, You accept that we have provided you with sufficient notice of any changes. You should seek professional advice regarding any possible legal requirements you must comply with in relation to use of the Website, and tools that related to participating in the Service provided by the Company.</p>

                        <h5>2. Definitions</h5>
                        <p>"<b>Affiliates</b>" refers to in relation in any party, any other company which, directly or indirectly, (i) is controlled by that party, (ii) controls that party, or (iii) is under common control with that party, and in respect of the Company, shall also include any fund, limited partnership or other collective investment vehicle or other person which is managed or advised by the Company’s team.</p>
                        <p>"<b>AML</b>" refers to Anti-Money Laundering.</p>
                        <p>"<b>Applicable Laws</b>" refers to acts, statutes, regulations, ordinances, treaties, guidelines, and policies issued by governmental organizations or regulatory bodies, including, but not limited to, the governing law stipulated under Laws of the England and Wales.</p>
                        <p>"<b>CFT</b>" refers to Combating the Financing of Terrorism.</p>
                        <p>"<b>Confidential Information</b>" refers to any non-public, proprietary information or documents of or related to the User or the Company (whether in writing, orally or by any other means) by or on behalf of the User to the Company and which if disclose in tangible or intangible form is marked confidential (including in visual, oral, or electronic form) relating to Us or any other User that was previously a User, which is provided or disclosed through the Company (or to any employees or agents) in connection with the use or participate in the Company Services.</p>
                        <p>"<b>Content</b>" refers to all content generated by the Company, including logos, identifying marks, images, illustrations, designs, icons, photographs, videos, text, any written or multimedia materials, Services, advertisements, software, code, data, files, archives, folders, or available downloads on the My Master War Platform. </p>
                        <p>"<b>Cookie</b>" refers to the small text files that are placed on your computer by the website that you visit. They are widely used in order to make websites work, or work more efficiently, as well as to provide information to the owner of the site.</p>
                        <p>"<b>IDO</b>" refers to Initial DEX Offerings or Initial Decentralized Exchange Offerings.</p>
                        <p>"<b>Partners</b>" refers to the Company, Animoca Brands, HUB Global, Morningstar Venture, Icetea Labs, Redkite, GameFi, DAO Maker, CoinGecko, Raptor Capital and Basic Capital</p>
                        <p>"<b>Prohibited Jurisdictions</b>" specifically refer to the Citizens and residents of and persons located in the United States of America, China, Hong Kong, and all sanctioned OFAC countries (DR Congo, Iran, Myanmar, Sudan, Iraq, Ivory Coast, North Korea, Syria, Zimbabwe, Cuba, Belarus, Liberia)</p>
                        <p>"<b>Project</b>" refers to the Company’s project being launched for IDO event on MyMasterWar Platform.</p>
                        <p>"<b>Services</b>" refer to the services provided to the Users through the Website, which include the accessibility to the new IDO token offered from each IDO fundraising project, Staking service and other relevant services available through the Platform and all free trials and beta services made available by the Company, which may be subject to periodic revision.</p>
                        <p>"<b>Staking or Stake</b>" refers to the staking or deposit service in which the Users can delegate or deposit the User’s $MAT Token in exchange for an opportunity to participate in the Services, and IDO Project.</p>
                        <p>"<b>$MAT Tokens</b>" MAT refers to My Master War Token, the token for the game ecosystem. MAT follows the standard BEP20 token on the Binance Smart Chain platform (BSC) and is controlled by smart contracts.</p>
                        <p>"<b>BEP20 Token</b>" refers to a blockchain-based token which is issued, stored, transferred, and transacted on the Binance Network.</p>
                        <p>"<b>MAT</b>" refers to the Binance Blockchain Protocol.</p>
                        <p>"<b>MyMasterWar Platform</b>" refers to the Website or platform that facilitates token swaps, and where the Project is officially launched and makes available its native Tokens (“Pool”) to be distributed to Users in the allocation rounds.</p>
                        <p>"<b>Wallet(s)</b>" refers to any digital wallet which is compatible with Binance Smart Chain Network, such as MetaMask.</p>

                        <h5>3. General Provisions</h5>
                        <ol style={{ listStyle: 'auto' }}>
                            <li>
                                <h6>Contractual Relationship</h6>
                                <div className="ps-4">
                                    <p>These Terms constitute a valid and binding agreement between You and the Company. The binding obligations stipulated in these Terms are enforceable.</p>
                                </div>
                            </li>

                            <li>
                                <h6>Revision and Amendments</h6>
                                <div className="ps-4">
                                    <p>The Company reserves the right to revise, amend, or update any clauses and provisions stipulated in these Terms in its sole discretion at any time. The Company will notify the revision or amendment of such clauses or provisions by updating these Terms and specify the 'Last Revised Date' displayed on a page of Terms and Conditions. Any revisions and updates on these Terms will be automatically effective upon their publication on the Platform, which includes the Website. Therefore, your continuity of accessing or using MyMasterWar Platform, and Services will be deemed that you agree to have read, understood, and accepted all revised terms and provisions. If you do not agree on any revised or updated terms and provisions, you should immediately stop accessing or using our Services. You are encouraged to frequently and carefully review these Terms to ensure that you understand these Terms.</p>
                                </div>
                            </li>

                            <li>
                                <h6>Privacy Policy</h6>
                                <div className="ps-4">
                                    <p>You acknowledge and confirm that you have read, understood, and agreed to provisions stipulated in the Privacy Policy, which will explain how the Company treats your information and protects your privacy when accessing or using the MyMasterWar Platform.</p>
                                    <p>By using MyMasterWar Platform, you hereby agree that the Company may collect, use your information, and anonymised data pertaining to your use of the Services for analytics, trends’ identification, and purpose of statistics to further enhance the effectiveness and efficiency of the Services.</p>
                                    <p>You hereby expressly authorise the Company to disclose any and all information relating to you in the Company’s possession to any law enforcement or government officials upon the request by the court order.</p>
                                </div>
                            </li>

                            <li>
                                <h6>Links to and from the Website</h6>
                                <ol style={{ listStyle: 'auto' }}>
                                    <li>
                                        <p>You may, through hypertext or other computer links, gain access form the Website to websites operated or made available, or otherwise licensed by persons other than us (“<b>Third Party Services</b>”). Such hyperlinks are provided for your convenience.</p>
                                    </li>
                                    <li>
                                        <p>A link from Third Party Services does not mean that the Company endorses or approves the content on such website or does not mean the Company is an operator of that website. You understand that you are solely responsible for determining the extent to which you may use or rely upon any content at any other Third-Party Services websites which you have accessed from the Website. The Company has no control over the content of these sites or resources and accept no reasonability for them or for any loss or damage that may arise from your use of them.</p>
                                    </li>
                                    <li>
                                        <p>The Company assumes no responsibility for the use of, or inability to use, any Third Party Services’ software, other materials, or contents posted and/or uploaded on such website and we will have no liability whatsoever to any person or entity for any inaccuracy or incompleteness of such third-party content. All intellectual property rights in and to Third Party Services are property of the respective third parties.</p>
                                    </li>
                                </ol>
                            </li>

                            <li>
                                <h6>Disclaimer for Accessibility of the Website and the Services</h6>
                                <ol style={{ listStyle: 'auto' }}>
                                    <li>
                                        <p>The Website merely facilitates the Users accessibility to the IDO Project tokens and does not provide you with any warranty or representation whatsoever regarding its quality, value, specification, fitness for the purpose, completeness or accuracy of its technology or infrastructure of the tokens.</p>
                                    </li>
                                    <li>
                                        <p>The Company will make all commercially reasonable attempts to facilitate information about the Project on the MyMasterWar Platform. However, you hereby acknowledge that the Company does not guarantee the accuracy, timeliness, or completeness of such information, and does not provide any warranty in connection with your use or reliance on such information. You agree that your use of the Project information will be at all your own risk. The Company will not be liable to you in any manner for the termination, interruption, delay, or inaccuracy of any Project information launched on the MyMasterWar Platform.</p>
                                    </li>
                                    <li>
                                        <p>To the extent permitted by applicable laws, the Website and the Company’s Services are provided on an ‘AS IS’ and ‘AS AVAILABLE’ basis. The Company does not warrant that the features, and functions contained in the Website and the Services will satisfy your preferences You hereby agree and acknowledge that your access and use of the Website and the Services are at your own risk, and you will be liable for any responsibility, consequences that may arise out of or in connection with the usage or accessibility of the Website and the Services. You expressly agree that the Company will have absolutely no liability in this regard.</p>
                                    </li>
                                    <li>
                                        <p>You hereby agree and acknowledge that the Website may contain links to a Third Party website or Third Party Services that are not owned or even controlled by the Company, thus the Company will hold no responsibility for the content or services, goods, or activities provided by such third-party website. You further agree that the Company has no liability directly or indirectly for any damage, loss, or consequence arising out of or in connection with the usage or reliance of any content, material, services available through the third-party website.</p>
                                    </li>
                                    <li>
                                        <p>The Company reserves the right to limit the availability of the Website to any person, geographic area, or jurisdiction we so desire and/or terminate your access to and use of the Website and the Services, at any time and in our sole discretion.</p>
                                    </li>
                                    <li>
                                        <p>The Company may, at our sole discretion, impose limits or restrictions on the use you make of the Website. Further, for commercial, security, technical, maintenance, legal or regulatory reasons, or due to any breach of these Terms, we may withdraw the Website or your access to the Website and the Services at any time and without notice to You.</p>
                                    </li>
                                </ol>
                            </li>

                            <li>
                                <h6>Information</h6>
                                <div className="ps-4">
                                    <p>You acknowledge that you are solely responsible for any submissions of all contents, remarks, suggestions, ideas, materials, feedbacks, or other information, including bug reports in relation to the Services provided through the Website including any submission to our social media platforms such as Twitter and Telegram, and you, not us, have full responsibility for such submissions, including their accuracy, legality, reliability, appropriateness, originality, and copyright. We shall reply on the information you have provided and will not verify it. Notwithstanding the foregoing, we have the right to refuse to post, remove, edit, or abridge any submission for any reason and to freely use, copy, disclose, publish, display, or exploit such submission as we deem necessary without any payment of royalty, acknowledgement prior to consent, we may retain copies of all information materials relevant to the Service.</p>
                                </div>
                            </li>
                        </ol>

                        <h5>4. Intellectual Property</h5>
                        <ol style={{ listStyle: 'auto' }}>
                            <li>
                                <p>All present and future copyright, title, interests in and to the Services, registered and unregistered trademarks, design rights, unregistered designs, database rights and all other present and future intellectual property rights and rights in the nature of intellectual property rights that exist in or in relation to the use and access of the Website and Services are owned by or otherwise licensed to the Company. Subject to your compliance with these Terms, the Company grants you a non-exclusive, non-sub license, and any limited license to merely use or access the Website and the Services in the permitted hereunder.</p>
                            </li>
                            <li>
                                <p>Except as expressly stated in these Terms, nothing in these Terms should be construed as conferring any right in or license to the Company’s or any other third party’s intellectual rights.</p>
                            </li>
                            <li>
                                <p>If and to the extent that any such intellectual property rights are vested in you by operation of law or otherwise, you agree to do any and all such acts and execute any and all such documents as we may reasonably request in order to assign such intellectual property rights back to us.</p>
                            </li>
                            <li>
                                <p>You agree and acknowledge that all Website content must not be copied or reproduced, modified, redistributed, used, created for derivative works, or otherwise dealt with for any other reason without being granted a written consent from the Company.</p>
                            </li>
                            <li>
                                <p>Third parties participating on the Website may permit the Company to utilise trademarks, copyrighted material, and other Intellectual Property associated with their businesses. The Company will not warrant or represent that the content of the Website does not infringe the rights of any third party.</p>
                            </li>
                        </ol>

                        <h5>5. Participation in the MyMasterWar Services</h5>
                        <ol style={{ listStyle: 'auto' }}>
                            <li>
                                <p>In term of Staking, you acknowledge and agree that the Company provides Staking Service to Users for purposes of delegating the User’s $MAT Tokens in exchange for an opportunity to participate on the Platform. You will provide the Staking instruction to the MyMasterWar Platform. Once the Staking instruction is received, the system will record the instruction and you will not be able to cancel or edit the Staking instruction. When the MyMasterWar Platform connect with your wallet, you will see your tier based on the numbers of $MAT Tokens held in your wallet.</p>
                                <div className="ps-3">
                                    <p>The Company reserves the right to reject your participation in Staking, if you fail any requirements through process or commit any other suspicious activity while participating in the Services provided by the Company.</p>
                                </div>
                            </li>
                            <li>
                                <p>Tokens being stacked are limited to $MAT Tokens only. Once you initiate Staking, you will be put in the allocation by using ‘MyMasterWar System’</p>
                            </li>
                            <li>
                                <p>In the event that you wish to unstake/withdraw your $MAT Tokens and gain the reward, you will provide the unstaking instruction to the MAT Platform. You acknowledge and agree that when you unstake your $MAT Tokens, the delivery of such $MAT Tokens into your wallet will take 7 days and the timing may vary based on time of day of the unstake/withdraw instruction and execution.</p>
                                <div className="ps-3">
                                    <p>The Company will levy a withdrawing fee or minimum transaction which will vary and be subject to the amendment only at its sole discretion of the Company periodically. The Company will not be liable for any loss caused or alleged caused by timing differences and economic lost associated with the actual delivery of the $MAT Tokens.</p>
                                </div>
                            </li>
                            <li>
                                <p>The Company reserves the right to amend any terms related to any specific Staking program at any time in its sole discretion. The Company will not be liable for any losses due to your misunderstanding of the Staking program terms and changes to such term’s rules.</p>
                            </li>
                        </ol>

                        <h5>6. Identity Verification through KYC/AML/CFT Process</h5>
                        <ol style={{ listStyle: 'auto' }}>
                            <li>
                                <p>Company is a permissionless, fully decentralized platform for token sales, swaps, and decentralized exchange. As a software development company, Red Kite has no role in enforcing KYC by default, however, we do provide such tools for fundraising entities using to enforce on their users, if the entities choose to do so. The use of KYC/AML tools on Red Kite by fundraising entities using the Service is completely at the discretion of said entities.</p>
                            </li>
                            <li>
                                <p>Although MyMasterWar Platform makes no warranty as to the merit, legality, or juridical nature of any token (including whether or not it is considered a security or financial instrument under any applicable Securities Laws), token sale or fundraiser on top of MyMasterWar Platform, we nonetheless understand the need of some token projects to require KYC/AML on their token sale participants.</p>
                                <p>Therefore, and at the sole behest of fundraising entities and/or competent regulatory authorities, MyMasterWar reserves the right at any time.</p>
                            </li>
                            <li>
                                <ul>
                                    <li>
                                        <p>To ask for any KYC documentation it deems necessary to determine the identity and location of a User.</p>
                                    </li>
                                    <li>
                                        <p>To restrict service and payment until identity is sufficiently determined.</p>
                                    </li>
                                    <li>
                                        <p>To share submitted KYC information and documentation to 3rd parties to verify the authenticity of submitted information, and the end-user (you) agrees to this by using the Service.</p>
                                    </li>
                                    <li>
                                        <p>To confiscate any and all funds that are found to be in violation of relevant and applicable anti-money laundering (AML) and countering terrorism financing (CFT) laws and regulations, and to cooperate with the competent authorities when and if necessary.</p>
                                    </li>
                                </ul>
                            </li>
                        </ol>

                        <h5>7. $MAT Tokens</h5>
                        <ol style={{ listStyle: 'auto' }}>
                            <li>
                                <p>The Company will issue a blockchain-based token called “$MAT Token” on Binance Smart Chain Network. $MAT Token is classified as the utility token designed to be used solely on the MyMasterWar Platform or on the Website.</p>
                            </li>
                            <li>
                                <p>$MAT Token is not considered as security of any kind, and it also does not carry any right to vote, manage or share in the MyMasterWar Platform.</p>
                            </li>
                            <li>
                                <p>$MAT Token is neither money nor legal tender/currency, whether fiat or otherwise, and it does not carry any value whether it is intrinsic or assigned.</p>
                            </li>
                        </ol>

                        <h5>8. Misuse of the Website</h5>
                        <ol style={{ listStyle: 'auto' }}>
                            <li>
                                <p>In the event of any misuse and/or abuse of the Website or breach any provision in these Terms, we reserve the right to block your access to the Website and other until the matter is solved.</p>
                            </li>
                            <li>
                                <p>Use of the Website for transmission, publication or storage of any material on or via the Website which is in violation of any applicable laws or regulations or any third-party’s rights is strictly prohibited, including but not limited to the use of the Website or the transmission, distribution, publication or storage any material on or via the Website in a matter or for the purpose which infringes copyright, trademark, trade secret or other intellectual property rights, is obscene or harmful to minors or constitutes an illegal act or harassment, is libellous or defamatory, violates any privacy or data protections laws, is fraudulent or breaches any exchange control laws.</p>
                            </li>
                        </ol>

                        <h5>9. MyMasterWar Services</h5>
                        <p>Representations and Warranties.<br />You hereby agree to make the following representations and warranties for accessing to the Website and the Services of the Company:</p>
                        <ol style={{ listStyle: 'auto' }}>
                            <li>
                                <p>You have full capacity and authority under the application laws to agree and bind yourself to these Terms.</p>
                            </li>
                            <li>
                                <p>You are eighteen years of age or older.</p>
                            </li>
                            <li>
                                <p>You are not a citizen or a resident from the Prohibited Jurisdictions, and you do not have any relevant connection with any jurisdictions where we have prohibited Services and access to the Website.</p>
                            </li>
                            <li>
                                <p>You are aware and agree to comply with all applicable domestic and international laws, statutes, ordinances, and regulations applicable to your use of the Website and the Services. Your use of the Website and the Services are not for any unlawful or illegal purposes, including but not limited to the usage against the copyright laws, AML/CFT laws.</p>
                            </li>
                            <li>
                                <p>You are the exclusive owner of $MAT Tokens and your other cryptocurrencies held in your wallet. The tokens maintained in your wallets are not and will not be derived from money laundering, terrorist financing, fraud, or any other illegal activities under any applicable laws. You further hereby acknowledge and agree that the Company will not be responsible for actions taken by you that result in the loss or destruction of the value of the tokens and rewards you hold in the wallet.</p>
                            </li>
                            <li>
                                <p>You validly undertake any action or enter into any transaction with regard to these Terms. You are solely responsible for use of the Website and the Services for all activities, or transactions that occur on or through your User account on MyMasterWar Platform.</p>
                            </li>
                            <li>
                                <p>You will provide only accurate, complete, and up-to-date information and documents, if any, for the purposes of accessing or using or participating MyMasterWar Services on the MyMasterWar Platform. You will further agree to put your effort to ensure that the confidentiality of your personal or credential information, including your wallet address is restricted, and safely maintained to your device you use to access the Website.</p>
                            </li>
                            <li>
                                <p>You will be acknowledged and agree that if you lose access to the wallet that you connected with the MyMasterWar Platform, the Company will not be able to help you recover these lost, or any $MAT Tokens back to your wallet. It will be your solely responsibility to manage your, and private key.</p>
                            </li>
                            <li>
                                <p>You will be responsible for obtaining the data network access necessary to use the Website. Your network's data and rates and fees may apply if you access or use the Website from a wireless-enabled device, and you will be responsible for such rates and fees.</p>
                            </li>
                            <li>
                                <p>You will understand and be aware of risks associated with accessing or using or participating in MyMasterWar Services, and you will be fully liable at your own risk.</p>
                            </li>
                            <li>
                                <p>You will be aware that you are subject to tax regulations in the jurisdiction you reside in and will be fully responsible for filling or reporting any taxes and paying them as required by the Applicable Laws. The Company will not be liable to compensate you for your tax obligations or advise you in relation to your tax obligations. Any uncertainties and unpredictable matters in tax legislation with respect to any tokens may expose you to any unknown or unforeseeable tax implications associated with your holding of tokens and the use of the Company Services for which the Company will have no liability. Moreover, you will not hold the Company liable for any expenses or losses resulting from unknown or unforeseeable tax implications.</p>
                            </li>
                            <li>
                                <p>You will not breach any of the provisions stipulated in these Terms, the Privacy Policy, or any Applicable Laws in any relevant jurisdictions.</p>
                            </li>
                            <li>
                                <p>You will not use the Website and the Services in one of any following manners, except as expressly permitted in these Terms, and the Company’s discretion.</p>
                                <div className="ps-3">You will not:</div>
                                <ol style={{ listStyle: 'lower-alpha' }}>
                                    <li>
                                        <p>infringe any propriety rights, including but not limited to copyrights, patents, trademarks, or trade secrets of the Company. </p>
                                    </li>
                                    <li>
                                        <p>use the Website or the Services to transmit any data or send or upload any material or content that contains viruses, Trojan horses, worms, time-bombs, keystroke loggers, spyware, adware, or any other harmful programmes or similar computer code designed to adversely affect the operation of the Website and the Services provided on the Platform.</p>
                                    </li>
                                    <li>
                                        <p>expressly or impliedly, use the Website and the Services that is deemed unlawful, offensive, malicious, threatening, libellous, defamatory, obscene, or otherwise objectionable or violates these Terms, or any other party's intellectual property.</p>
                                    </li>
                                    <li>
                                        <p>modify, make any back-up or archival copies of the Platform or any part thereof including disassembling, and you will also not adapt, hack the Website or modify another website to imply that it is associated with the Website falsely.</p>
                                    </li>
                                    <li>
                                        <p>crawl, scrape, or otherwise cache any content from the Website, and you will agree not to use any automated data collection methods, data mining, robots, or scraping or any data gathering methods of any kind on the Website.</p>
                                    </li>
                                    <li>
                                        <p>use the Website or any of its contents for advertising or soliciting, for any other commercial, political, or religious purpose, or to compete, either directly or indirectly with the Company.</p>
                                    </li>
                                </ol>
                            </li>
                            <li>
                                <p>You will defend, indemnify, and not hold the Company, its Affiliates, each of their respective employees, officers, directors, and representatives liable to and against any claims, damages, losses, liabilities, costs, and expenses (including reasonable attorney's fees), arising out of or relating to any third-party claim concerning these Terms, or your use of the Company’s Services in violation of these Terms and applicable laws.</p>
                            </li>
                            <li>
                                <p>Each of the Representations and Warranties will survive and continue to have full force and effect after the execution of these Terms.</p>
                            </li>
                        </ol>

                        <h5>10. Risk Disclosure</h5>
                        <p>By accessing or using or participating in the Company’s Services, you expressly acknowledge and assume the following risks:</p>
                        <ol style={{ listStyle: 'auto' }}>
                            <li>
                                <p>Risk of loss in value</p>
                                <div className="ps-3">
                                    <p>Tokens or any digital currencies are not issued by any central banks or national, supra-national, or quasi-national organizations. They are also not backed by any hard assets or other credit. The value of tokens or any digital currencies is affected by several factors, including but not limited to, the total number of tokens or any digital currencies in existence, the continued willingness of market participants to exchange government-issued currency for tokens or digital currencies , purchasers' expectations with respect to the rate of inflation of fiat currencies, purchasers' expectations with respect to the rate of deflation of cryptocurrencies, interest rates, currency exchange rates, cyber theft of cryptocurrencies from online digital wallet providers, or news of such theft from such providers or individuals' digital wallets, investment and trading activities of large investors, monetary policies of the governments, trade restrictions, currency devaluations and revaluations, regulatory measures, the global or regional political, economic or financial events and situations. Thus, all these factors will affect the value of tokens or digital currencies, which may result in the permanent partial or total loss of the value of a particular tokens or digital currencies. No one will be obliged to guarantee the liquidity or the market price of any of the tokens or digital currencies maintained into your wallets. The volatility and unpredictability of the value of tokens or digital currencies relative to the government-issued currency may result in a significant loss over a short period of time.</p>
                                </div>
                            </li>
                            <li>
                                <p>The regulatory regime governing tokens or digital currencies</p>
                                <div className="ps-3">
                                    <p>The regulatory framework relating to tokens or digital currencies remains unsettled, and any laws, regulations, or guidelines may be significantly revised and amended which will materially and adversely affect the value of tokens or digital currencies and your Services on the Website or MyMasterWar Platform as follows</p>
                                </div>
                            </li>
                            <li>
                                <p>Technical and System Failure affected the obligations stipulated in these Terms the Company may experience system failures, unplanned interruptions in its Binance Network or Services, hardware or software defects, security breaches or other causes that could adversely affect the Company infrastructure network, which includes the Website and MyMasterWar Platform.</p>
                                <div className="ps-3">
                                    <p>The Company is unable to anticipate the occurrence of hacks, cyber-attacks, mining attacks, including but not limited to double-spend attacks, majority mining power attacks and selfish-mining attacks, distributed denial of service attacks or errors, vulnerabilities or defects in the Website, $MAT Tokens, Users' wallets or any technology, including but not limited to smart contract technology. Also, the Company is unable to detect the hacks as mentioned earlier, mining attacks, cyber-attacks, distributed denials of service errors vulnerabilities, or defects in a timely manner and does not have sufficient resources to efficiently cope with multiple service incidents happening simultaneously or in rapid succession.</p>
                                    <p>In addition, the Company's network or Services could be disrupted by numerous events, including natural disasters, equipment breakdown, network connectivity downtime, power losses, or even intentional disruptions of its Services, such as disruptions caused by software viruses or attacks by unauthorized users, some of which are beyond the Company's control. Although the Company has taken steps and used its best endeavour against malicious attacks on its appliances or its infrastructure, which are critical for the maintenance of the MyMasterWar Platform and its Services, there can be no assurance that cyber-attacks, such as distributed denials of service, will not be attempted in the future, and that the Company's enhanced security measures will be effective. Any significant breach of the Company's security measures or other disruptions resulting in a compromise of the usability, stability and security of the Company's network or Services, including the MyMasterWar Platform, may adversely affect $MAT Tokens.</p>
                                </div>
                            </li>
                            <li>
                                <p>The Company will have no liability for any delay, error, interruption, or failure to perform any obligation under these Terms where the delay or failure is directly or indirectly resulting from any causes beyond the Company's control, including, but not limited to:</p>
                                <ol style={{ listStyle: 'auto' }}>
                                    <li>
                                        <p>Acts of God, nature, or court of government;</p>
                                    </li>
                                    <li>
                                        <p>Failure or interruption of public or private telecommunication networks, the failure of Binance Network, communication channels or information systems;</p>
                                    </li>
                                    <li>
                                        <p>Acts or omission of acts of a party for whom We are not responsible;</p>
                                    </li>
                                    <li>
                                        <p>Delay, failure, or interruption in, or unavailability of, Third Party Services;</p>
                                    </li>
                                    <li>
                                        <p>Strikes, lockouts, labour disputes, wars, terrorist acts and riots.</p>
                                    </li>
                                </ol>
                            </li>
                            <li>
                                <p>YOU UNDERSTAND AND AGREE THAT YOUR USE OF THE COMPANY SERVICES AND THE WEBSITE IS COMPLETELY AT YOUR OWN RISK. THIS CLAUSE IS NOT EXHAUSTIVE AND DOES NOT DISCLOSE ALL THE RISKS ASSOCIATED WITH DIGITAL CURRENCIES AND THE USE OF SERVICES. THEREFORE, YOU ARE RECOMMENDED TO CAREFULLY CONSIDER WHETHER SUCH USE IS SUITABLE FOR YOU IN LIGHT OF YOUR JUDGEMENT, CIRCUMSTANCES, AND FINANCIAL POSITION.</p>
                            </li>
                        </ol>

                        <h5>11. Limitation of Liability</h5>
                        <ol style={{ listStyle: 'auto' }}>
                            <li>
                                <p>NOTWITHSTANDING ANY PROVISIONS WITHIN THESE TERMS, IN NO EVENT WILL THE COMPANY, ITS AFFILIATES, OR ITS EMPLOYEES, AGENTS, OFFICERS, OR DIRECTORS BE LIABLE TO THE USER FOR ANY INCIDENTAL, SPECIAL, EXEMPLARY, PUNITIVE, INDIRECT, OR CONSEQUENTIAL DAMAGES OF ANY KIND, UNDER ANY LEGAL THEORY, ARISING OUT OF OR IN CONNECTION WITH YOUR USE, OR INABILITY TO USE, THE WEBSITE, ANY WEBSITES LINKED TO IT, ANY CONTENT ON THE WEBSITE OR OTHER SUCH WEBSITES, OR ANY OTHER SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE, OR OTHER SUCH WEBSITES, INCLUDING, BUT NOT LIMITED TO, LOST REVENUE, LOST PROFITS, LOSS OF BUSINESS OR ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF GOODWILL, LOSS OF DATA, LOSS OF YOUR CREDENTIAL INFORMATION, LOSS OR INTERRUPTION OF TECHNOLOGY, LOSS OF USE OF SERVICE OR EQUIPMENT, EVEN IF THE USER WAS ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, AND WHETHER ARISING UNDER A THEORY OF CONTRACT, TORT, STRICT LIABILITY OR OTHERWISE. THE COMPANY WILL ALSO HAVE NO LIABILITY FOR ANY EMERGENCY AND/OR UNFORESEEABLE INCIDENTS RELATED TO YOUR USE OF THE COMPANY SERVICES ON THE MYMASTERWAR PLATFORM SUCH AS STOLEN PRIVATE KEY, OR HACKED ACCOUNTS</p>
                            </li>
                            <li>
                                <p>Except as expressly provided in these Terms, and to the maximum extent permitted by any Applicable Laws, we disclaim all other representations or warranties, express or implied, made to you, your affiliates, or any other person, including, without limitation, any warranties regarding the quality, suitability, merchantability, fitness for a particular purpose or otherwise (regardless of any course of dealing, custom or usage of trade) of any service provided incidental to the Company Services under these Terms.</p>
                                <div className="ps-3">
                                    In no event will our aggregate liability for any loss or damage that arises in connection with the Company Services exceed the purchase amount you paid to participate in the MyMasterWar Platform, if any, during a twelve (12) month period immediately preceding the event that gave rise to the claim for liability. The preceding limitations of liability will apply to the fullest actual amount you paid to participate or access in the MyMasterWar Platform.
                                </div>
                            </li>
                            <li>
                                <p>Except as expressly provided in these Terms, and to the fullest extent permitted by any Applicable Laws, the Company, its Affiliates, and their related parties each disclaim all liability to you for any loss or damage arising out of or due to:</p>
                                <ol style={{ listStyle: 'auto' }}>
                                    <li>
                                        <p>your use of, inability to use, or availability or unavailability of the Services, including any Third Party Services made available through the Services;</p>
                                    </li>
                                    <li>
                                        <p>the occurrence or existence of any defect, interruption, deletion of files, delays in the operation or transmission of information to, from, or through the Services, communications failure, theft, destruction or unauthorised access to the Company’s records, programs, Services, server, or other infrastructure relating to the Services;</p>
                                    </li>
                                    <li>
                                        <p>the Services being infected with any malicious code or viruses; or</p>
                                    </li>
                                    <li>
                                        <p>the failure of the Services to remain operational for any period of time.</p>
                                    </li>
                                </ol>
                            </li>
                        </ol>

                        <h5>11. Indemnification</h5>
                        <p>You irrevocably undertake the responsibility of fully indemnifying and holding harmless each of the Company, its Affiliates, licensors, shareholders, officers, directors, managers, employees, and agents from and against any and all losses, claims, actions, proceedings, damages, demands, judgements, sums, liabilities, damages, costs, charges and expenses, including, but not limited to, any reasonable attorney's fees or penalties imposed by any regulatory authority, and reimbursements arising out of or related to the following situations:</p>
                        <ol style={{ listStyle: 'auto' }}>
                            <li>
                                <p>Your use or any person using the Services on your behalf or participation in accordance with the Company Services on the Website or the MyMasterWar Platform;</p>
                            </li>
                            <li>
                                <p>Your breach of or our enforcement of these Terms;</p>
                            </li>
                            <li>
                                <p>Any violations of Applicable Laws, regulation, or rights of any third-party during your use or participate in MyMasterWar Platform.</p>
                            </li>
                        </ol>
                        <p>If you are obligated to indemnify the Company, its Affiliates, shareholders, licensors, officers, directors, managers, employees, and agents, the Company will have the right, at our sole discretion, to control any action or proceeding and to determine whether the Company wishes to proceed, or settle, and if so, on what terms or provisions.</p>

                        <h5>12. Termination</h5>
                        <ol style={{ listStyle: 'auto' }}>
                            <li>
                                <p>These Terms will be immediately terminated by discontinuing your use, or participate in the Company Services and you agree to terminate the accessibility on the Platform.</p>
                            </li>
                            <li>
                                <p>These Terms can be suspended or terminated without a notice from us if there is a reasonable ground to believe that you have breached any of the terms or provisions stipulated in these Terms, or if you do not comply with these Terms.</p>
                            </li>
                            <li>
                                <p>The termination of these Terms will not prevent the Company from seeking remedies from you in the case where you breach any terms or provisions before such termination. The Company will not be liable to you or to any third party for any termination, suspension, or modification of your access to the Services.</p>
                            </li>
                            <li>
                                <p>Any ongoing obligation to you as well as the provisions regarding</p>
                                <ol style={{ listStyle: 'upper-roman' }}>
                                    <li>
                                        <p>the Company's Intellectual Property</p>
                                    </li>
                                    <li>
                                        <p>No solicitation or Offering</p>
                                    </li>
                                    <li>
                                        <p>indemnification</p>
                                    </li>
                                    <li>
                                        <p>Limitation of liability, and</p>
                                    </li>
                                    <li>
                                        <p>any other provisions designed to survive, will survive any termination or expiration of these Terms for any reason.</p>
                                    </li>
                                </ol>
                            </li>
                        </ol>

                        <h5>13. No Financial and Legal Advice</h5>
                        <p>The Company is merely a technology platform, and we are not your broker, intermediary, agent, or legal advisor and has no fiduciary relationship or obligation to you in connection with any decisions or activities effected by you using or participating on the Website or the MyMasterWar Platform. No communication or information provided to you by the Company is intended as or will be considered or construed as, the solicitation of an offer to buy, the investment advice, financial advice, legal advice, or any other sort of advice. All Services, Transactions, and Investments will be executed automatically based on the parameters of your consideration. You will be solely responsible for determining whether any services, or investments are suitable and match your interests according to your judgement, objectives, circumstances and risk tolerance. You will be solely responsible for any losses or liabilities therefrom.</p>
                        <p>Before executing any transactions, purchasing $MAT Tokens, you should consult with your independent financial, legal, or tax professionals. The Company will not be liable for the decisions you make to access and purchase through the Company.</p>

                        <h5>14. Notice/Announcement</h5>
                        <p>Any notice, requests, demands, and determinations for the Company under these Terms (other than routine operational communications) shall be sent to <a href="https://support.us@mymasterwar.com/" target="_blank">Contact Support</a></p>

                        <h5>15. Governing Law, Resolving Disputes, Arbitration and Class Action Waiver</h5>
                        <p>PLEASE READ THIS SECTION CAREFULLY AS IT INVOLVES A WAIVER OF CERTAIN RIGHTS TO BRING LEGAL PROCEEDINGS, INCLUDING A CLASS ACTION.</p>
                        <ol style={{ listStyle: 'auto' }}>
                            <li>
                                <p>Notice of Claim and Dispute Resolution Period</p>
                                <div className="ps-3">
                                    <p>Please contact the Company first. The Company will seek to address your concerns without resorting to formal legal proceedings whenever possible. If you have a dispute with the Company, you should contact the Company, and a case number will be assigned. The Company will attempt to resolve your dispute internally as soon as possible. The parties will agree to negotiate in good faith to resolve the dispute and discussions will remain confidential and subject to applicable laws protecting settlement discussions from use as evidence in any legal proceeding.</p>
                                </div>
                            </li>
                            <li>
                                <p>Agreement to Arbitrate</p>
                                <div className="ps-3">
                                    <p>You and the Company agree that subject to paragraph 16.1 above, any dispute, claim, or controversy between you and the Company that arises in connection with, or relating in any way, to these Terms, or to your relationship with the Company as a user of the Company Services (whether by contract, tort, statute, fraud, misrepresentation, or any other legal theory, and whether the claims arise during or after the termination of these Terms) will be determined by a mandatory final and binding individual arbitration rather than a class action, except as set forth below under Exceptions to the Agreement to Arbitrate. You and the Company further agree that the arbitrator will have the exclusive power to rule on his or her jurisdiction, including, without limitation, any objections with respect to the existence, scope, or validity of the Agreement to Arbitrate, or to the arbitrability of any claim or counterclaim. Arbitration is more informal than a lawsuit in court. THERE IS NO JUDGE OR JURY IN ARBITRATION, AND COURT REVIEW OF AN ARBITRATION AWARD IS LIMITED. There may be more limited discovery than in court. The arbitrator must follow this agreement and can award the same damages and relief as a court, including, if applicable, attorney fees, except the arbitrator may not award declaratory or injunctive relief benefiting anyone but the parties to the arbitration. The arbitration provisions set forth in this Section will survive termination of these Terms.</p>
                                </div>
                            </li>
                            <li>
                                <p>Arbitration Rules</p>
                                <div className="ps-3">
                                    <p>Any dispute arising out of or in connection with these Terms including any question regarding its existence, validity or termination, shall be referred to and finally resolved by arbitration administered by the Singapore International Arbitration Centre (“SIAC”) in accordance with the Arbitration Rules of the Singapore International Arbitration Centre ("SIAC Rules") for the time being in force, which rules are deemed to be incorporated by reference in these Terms. The seat of the arbitration shall be Singapore. The Tribunal shall consist of one arbitrator. The language of the arbitration shall be English.</p>
                                </div>
                            </li>
                            <li>
                                <p>Governing Law/Jurisdiction</p>
                                <div className="ps-3">
                                    <p>The governing law of the arbitration will be that of the England and Wales.</p>
                                </div>
                            </li>
                            <li>
                                <p>Confidentiality</p>
                                <div className="ps-3">
                                    <p>The parties agree that the arbitration will be kept confidential. The existence of the arbitration, any non-public information provided in the arbitration, and any submissions, orders or awards made in the arbitration will not be disclosed to any non-parties except the tribunal, the parties, their counsel, experts, witnesses, accountants and auditors, insurers and reinsurers, and any other person necessary to facilitate the arbitration. Notwithstanding the preceding, a party may disclose information to the extent that disclosure may be required to fulfil a legal duty, protect, or pursue a legal right, or enforce or challenge an award in bona fide legal proceedings. This confidentiality provision will survive the termination of these Terms and any arbitration brought under these Terms.</p>
                                </div>
                            </li>
                            <li>
                                <p>Class Action Waiver</p>
                                <div className="ps-3">
                                    <p>You and the Company agree that any claims relevant to these Terms, or your relationship with the Company will be brought against the other party in arbitration on an individual basis only and not as a plaintiff or class member in a purported class or representative action. You and the Company further agree to waive any right for such claims to be brought, heard, or arbitrated as a class, collective, representative, or private attorney general action, to the extent permissible by applicable laws. Combining or consolidating individual arbitrations into a single arbitration is not permitted without the consent of all parties involved.</p>
                                </div>
                            </li>
                            <li>
                                <p>The Company reserves the right to update, modify, revise, suspend, or make future changes to Section 16.2 regarding the parties' Agreement to Arbitrate, subject to applicable laws. You hereby consent and agree that it is your responsibility to ensure that your understanding of this Clause is up to date. Subject to applicable laws, your continued use of your MyMasterWar’s account will be interpreted as your acceptance of any modifications to Clause 16 regarding the parties' Agreement to Arbitrate. You agree that if you object to the modifications to Clause 16, the Company may block access to your account pending closure of your account. In such circumstances, these Terms prior to modification will remain in full force and affect the pending closure of your accessibility.</p>
                            </li>
                        </ol>

                        <h5>16. Miscellaneous</h5>
                        <ol style={{ listStyle: 'auto' }}>
                            <li>
                                <p>Severability</p>
                                <div className="ps-3">
                                    <p>If any of the provisions in these Terms is found by a court of competent authority to be invalid, void, unlawful or unenforceable under any applicable laws, such unenforceability or invalidity will not render these Terms unenforceable or invalid as a whole, and such provisions will be deleted without affecting the remaining provisions herein.</p>
                                </div>
                            </li>
                            <li>
                                <p>Variation of Terms</p>
                                <div className="ps-3">
                                    <p>The Company has the right to revise these Terms at our sole discretion at any time, and by using the Website or other MyMasterWar Platform, you will be expected to review such Terms regularly to ensure that you understand all provisions stipulated in these Terms.</p>
                                </div>
                            </li>
                            <li>
                                <p>Assignment</p>
                                <div className="ps-3">
                                    <p>The Company will be allowed to assign, transfer, and subcontract Our rights and/or obligations under these Terms without the need to provide you any notification or acquire your consent. Nevertheless, you will not be permitted to assign, transfer, or subcontract any of your rights and/or obligations under these Terms.</p>
                                </div>
                            </li>
                            <li>
                                <p>Entire Agreement</p>
                                <div className="ps-3">
                                    <p>These Terms, including the Privacy Policy and any rules contained on the Website or others MyMasterWar Platform, constitute the sole and entire agreement between You and the Company with respect to your use of the MyMasterWar Platform, and supersedes other prior or contemporaneous negotiations, discussions, agreements, understandings, representations, and warranties, both written and oral, between You and the Company with respect to such subject matter.</p>
                                </div>
                            </li>
                            <li>
                                <p>No Third-Party Rights</p>
                                <div className="ps-3">
                                    <p>Nothing in these Terms will be deemed to create any rights to any creditors or other persons, not a party hereto. Moreover, these Terms will not be construed, in any respect, to be a contract, in whole or in part, for the benefit of any third parties.</p>
                                </div>
                            </li>
                            <li>
                                <p>Clickwrap</p>
                                <div className="ps-3">
                                    <p>The Company may deliver the Service through electronic means such as download links, graphical, Tools or other technologies for providing the Service for users. The user interfaces to such electronic means may require that the users agree to these Terms by checking a box, clicking a button, or continuing with the Service. If user through such action the user becomes a Party to these Terms. Such an action of acceptance shall be sufficient to bind the users to the terms and conditions herein these Terms.</p>
                                </div>
                            </li>
                            <li>
                                <p>Waiver</p>
                                <div className="ps-3">
                                    <p>The failure of one Party to require the performance of any provision will not affect that Party's right to require performance at any time thereafter. At the same time, the waiver of one Party to seek recovery for the other Party's violation of these Terms of any provisions of applicable terms will not constitute a waiver by that Party of any subsequent breach or violation by the other Party or of the provision itself.</p>
                                </div>
                            </li>
                        </ol>
                    </div>
                </div>
            </div>
        </Container>
    </>
}
export default TermOfUsePage;