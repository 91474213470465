import { ACTION_CONST } from "../../constants/action";

const INITIAL_STATE = {
  walletInfo: {},
  stakingInfo: {},
  stakingWalletInfo: {},
  jobUnStakeTime: 0,
  matBalance: 0,
  bnbBalance: 0,
  jobGetBalance: 0,
  // staking: {
  //   url: "",
  //   redirect: false,
  // },
  jobStakingStatus: 0,
};
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ACTION_CONST.GET_INFO_WALLET:
      return {
        ...state,
        walletInfo: action.data,
      };

    //merge staking
    case ACTION_CONST.GET_STAKING_INFO:
      return {
        ...state,
        stakingInfo: action.data,
      };

    case ACTION_CONST.GET_STAKING_WALLET_INFO:
      return {
        ...state,
        stakingWalletInfo: action.data,
      };

    case ACTION_CONST.GET_MAT_WALLET_BALANCE:
      return {
        ...state,
        matBalance: action.data,
      };

    case ACTION_CONST.GET_BNB_BALANCE:
      return {
        ...state,
        bnbBalance: action.data,
      };

    default:
      return state;
  }
};
