import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getStakingContractInfo } from "../utils/stakingHelper";
import { ACTION_CONST } from "../../constants";
import { changeTab } from "../../redux/action/app";

import { helpers } from "../utils";
import { get } from "lodash";
import { useLatestBlockNumber, useStakingInfo, useSubmit } from "../../hook/useState";
import RightBar from "./RightBar";

const StakingPortalPage = ({ selectedTab, children }) => {
    const dispatch = useDispatch();
    const stakingInfo = useStakingInfo();
    const latestBlock = useLatestBlockNumber();
    const stakerCount = get(stakingInfo, 'stakerCount', 0);
    const apyPercentage = get(stakingInfo, 'apyPercentage', 0);
    const totalStakedAmount = get(stakingInfo, 'totalStakedAmount', 0);
    const submitOK = useSubmit();

    useEffect(() => {
        dispatch(changeTab(1));
    }, [])

    useEffect(() => {

        getStakingContractInfo().then(data => {

            dispatch({ type: ACTION_CONST.GET_STAKING_INFO, data: data });
        })


    }, [dispatch, submitOK, latestBlock]);

    const [isShowNotice, setIsShowNotice] = useState(true);

    useEffect(() => {
        setIsShowNotice(!sessionStorage.getItem('HIDE_NOTICE'));
    }, [selectedTab]);

    return (
        <>
            {
                stakingInfo["isStakingPaused"] ?
                    <div className="container mt-4 text-center d-md-block d-none">
                        <span className="pp-banner-top">
                            <i className="mdi mdi-alert-outline me-2 text-danger">
                            </i> All staking functions are temporarily paused. Please check back again later.
                        </span>
                    </div> : <></>
            }
            <div className="page-container">
                {
                    stakingInfo["isStakingPaused"] ?
                        <div className="container mt-4 text-center d-md-none d-block pp-notice">
                            <span className="pp-banner-top">
                                <i className="mdi mdi-alert-outline me-2 text-danger">
                                </i> All staking functions are temporarily paused. Please check back again later.
                            </span>
                        </div> : <></>
                }
                <div className="p-content">
                    <div className="container-fluid mt-3 p-scroll">
                        <div className="p-content-tabs">
                            <div className="row align-items-start">
                                <div className="col-xl-4">
                                    <div style={{ marginTop: '-5px' }} className="mb-xl-0 mb-3">
                                        {selectedTab == 1 && <h4 className="p-tab-title mb-0">Stake <br className="d-none d-xl-block" />your <span>$MAT</span></h4>}
                                        {selectedTab == 2 && <h4 className="p-tab-title mb-0">Unstake <br className="d-none d-xl-block" />your <span>$MAT</span></h4>}
                                        {selectedTab == 3 && <h4 className="p-tab-title mb-0">Withdraw your <span>$MAT</span></h4>}
                                    </div>
                                </div>
                                <div className="col-xl-8 mt-xl-0 mt-3">
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="detail-card mb-5 mx-xl-1">
                                                <div className="detail-card-title">
                                                    <span>Number of Stakers</span>
                                                </div>
                                                <div className="detail-card-body">
                                                    <h3 className="mt-3 mb-2 detail-card-value">{stakerCount}</h3>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-4">
                                            <div className="detail-card mb-5">
                                                <div className="detail-card-title">
                                                    <span>Total <span>MAT</span> Staked</span>
                                                </div>
                                                <div className="detail-card-body">
                                                    <h3 className="mt-3 mb-2 detail-card-value">{helpers.formatNumberDownRound(totalStakedAmount, 2)}</h3>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-4">
                                            <div className="detail-card mb-5 mx-xl-1">
                                                <div className="detail-card-title">
                                                    <span>APY</span>
                                                </div>
                                                <div className="detail-card-body">
                                                    <h3 className="mt-3 mb-2 detail-card-value">{helpers.formatNumberDownRound((apyPercentage / 100).toString(), 2)}%</h3>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-content pt-5 mt-5" id="myTabContent">
                                {children}
                            </div>
                        </div>
                    </div>
                </div>
                <RightBar />
            </div>

            {isShowNotice && <div className="tq_contract" id="tq_contract">
                <div className="tq_contract_content">
                    <div className="position-relative" style={{ fontSize: '18px' }}>
                        <button type="button" className="position-absolute btn btn-link btn-sm text-danger" style={{ right: '-40px', top: '-5px', fontSize: '24px' }} onClick={() => {
                            sessionStorage.setItem('HIDE_NOTICE', true);
                            setIsShowNotice(false);
                        }}>
                            <i className="fas fa-times"></i>
                        </button>
                        <div>Interest will be paid at 0:30 UTC every day</div>
                    </div>
                </div>
            </div>
            }
        </>
    );
};

export default StakingPortalPage;
