export const extensionName = {
  metamask: "METAMASK",
  binanceExtension: "BINANCE_EXTENSION",
  trustWallet: "TRUST_WALLET",
};

export const STAKE_ACTION = {
  STAKING: 'STAKING',
  UNSTACKING: 'UNSTACKING',
  WITHDRAW: 'WITHDRAW'
};

export const MAPPING_STAKE_ACTION = {
  [STAKE_ACTION.STAKING]: 'Staking',
  [STAKE_ACTION.UNSTACKING]: 'Unstaking',
  [STAKE_ACTION.WITHDRAW]: 'Withdrawing',
};

export const STATE ={
  NONE:"none",
  SUCCESS:'success',
  ERROR: 'error',
  SUBMIT:'submit'
}

export const STATUS = {
  APPROVING: 'APPROVING',
  APPROVED: 'APPROVED',
  APPROVE_FAILS: 'APPROVE_FAILS',
  STAKING_DEPOSIT_FAIL: 'STAKING_DEPOSIT_FAIL',
  STAKING_DEPOSIT_SUBMIT:'STAKING_DEPOSIT_SUBMIT',
  STAKING_DEPOSIT_SUCCESS:'STAKING_DEPOSIT_SUCCESS',
  STAKING_EXECUTE_WITHDRAWAL_SUBMIT: 'STAKING_EXECUTE_WITHDRAWAL_SUBMIT',
  STAKING_EXECUTE_WITHDRAWAL_FAIL: 'STAKING_EXECUTE_WITHDRAWAL_FAIL',
  STAKING_EXECUTE_WITHDRAWAL_SUCCESS: 'STAKING_EXECUTE_WITHDRAWAL_SUCCESS',
  STAKING_EXECUTE_WITHDRAW_REWARDS_SUBMIT: 'STAKING_EXECUTE_WITHDRAW_REWARDS_SUBMIT',
  STAKING_EXECUTE_WITHDRAW_REWARDS_FAIL:'STAKING_EXECUTE_WITHDRAW_REWARDS_FAIL',
  STAKING_EXECUTE_WITHDRAW_REWARDS_SUCCESS:'STAKING_EXECUTE_WITHDRAW_REWARDS_SUCCESS',
  STAKING_REWARDS_SUBMIT:'STAKING_REWARDS_SUBMIT',
  STAKING_REWARDS_FAIL:'STAKING_REWARDS_FAIL',
  STAKING_REWARDS_SUCCESS: 'STAKING_REWARDS_SUCCESS',
  STAKING_INITIATE_WITHDRAWAL_SUBMIT: 'STAKING_INITIATE_WITHDRAWAL_SUBMIT',
  STAKING_INITIATE_WITHDRAWAL_SUCCESS: 'STAKING_INITIATE_WITHDRAWAL_SUCCESS',
  STAKING_INITIATE_WITHDRAWAL_FAIL: 'STAKING_INITIATE_WITHDRAWAL_FAIL',

}
