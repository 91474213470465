import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { ACTION_CONST, ROUTES, STAKE_ACTION, STATUS } from "../../constants";
import { BSC_EXPLORER, MODE } from "../../_configs";
import { BigNumber } from "bignumber.js";
import { helpers } from "../../shared/utils";
import StakingPortalPage from "../../shared/layout/StakingPortal";
import { useBnbBalance, useStakingInfo, useStakingWalletInfo, useWeb3Utils } from "../../hook/useState";
import { useActiveWeb3React } from "../../hook";
import ConfirmationModal from "../../components/ConfirmationModal";

const UnStakingPage = () => {
    const dispatch = useDispatch();
    const { account, chainId, library } = useActiveWeb3React();
    const [unStakeCurrentStep, setUnStakeCurrentStep] = useState(1);
    const walletUtils = useWeb3Utils();
    const stakingInfo = useStakingInfo();
    const stakingWalletInfo = useStakingWalletInfo();
    const bnbBalance = useBnbBalance()

    const [acceptTerm, setAcceptTerm] = useState(false);
    const [enableUnStakeBtn, setEnableUnStakeBtn] = useState(false);
    // const [stakedDuration, setStakedDuration] = useState(0);
    // const [totalPctFee, setTotalPctFee] = useState(0);
    const [transactionHash, setTransactionHash] = useState("");
    const [unStakeAmount, setUnStakeAmount] = useState('');
    const [isMaxAmount, setIsMaxAmount] = useState(false);
    const [isSubmitOK, setIsSubmitOK] = useState(false);

    const [loading, setLoading] = useState(0);
    const [visible, setVisible] = useState(false);

    //enable unstake button
    useEffect(() => {
        if (unStakeCurrentStep === 2) {
            if (acceptTerm &&
                account &&
                !stakingInfo["isStakingPaused"] &&
                parseFloat(stakingWalletInfo["stakedAmount"]) > 0 &&
                bnbBalance > 0 &&
                stakingWalletInfo["withdrawTimestamp"] === 0
            ) {
                setEnableUnStakeBtn(true);
            } else {
                setEnableUnStakeBtn(false);
            }
        }
        if (unStakeCurrentStep === 1) setEnableUnStakeBtn(true);
        // if (stakingInfo && stakingWalletInfo["stakedDuration"]) {
        //     setStakedDuration(stakingWalletInfo["stakedDuration"]);
        //     setTotalPctFee(stakingWalletInfo["totalPctFee"] / 100);
        // }
    }, [acceptTerm, account, bnbBalance, stakingInfo, stakingWalletInfo, unStakeCurrentStep])


    const unStakeBack = () => {
        if (unStakeCurrentStep === 1) {
            return;
        } else {
            setUnStakeCurrentStep(unStakeCurrentStep - 1);
        }
    }

    const unStakeNext = () => {
        if (unStakeCurrentStep === 1) {
            setEnableUnStakeBtn(false);
            setUnStakeCurrentStep(unStakeCurrentStep + 1);
            return;
        }

        if (unStakeCurrentStep === 2 && parseFloat(unStakeAmount) === 0) {
            setEnableUnStakeBtn(false);
            setUnStakeCurrentStep(unStakeCurrentStep + 1);
            return;
        }

        if (unStakeCurrentStep === 4) {
            if (walletUtils) {
                setVisible(true);
                dispatch({
                    type: ACTION_CONST.REQUEST_SUBMIT
                })
                walletUtils.stakingInitiateWithdrawal({ amount: isMaxAmount ? stakingWalletInfo["stakedAmount"] : unStakeAmount }, data => {
                    // console.log(data);
                    if (data.status === STATUS.STAKING_INITIATE_WITHDRAWAL_SUCCESS) {
                        setVisible(false);
                        setLoading(1);
                        dispatch({
                            type: ACTION_CONST.REQUEST_DONE
                        })
                        setIsSubmitOK(true);
                        setTransactionHash(data.txID);

                        setUnStakeCurrentStep(5);

                    }
                    if (data.status === STATUS.STAKING_INITIATE_WITHDRAWAL_FAIL) {
                        // setVisible(false);
                        setLoading(-1);
                        dispatch({
                            type: ACTION_CONST.REQUEST_DONE
                        })

                        dispatch({
                            type: ACTION_CONST.ALERT_FAILS,
                            message: "INIT Unstake fails"
                        })
                    }
                })
            }
        } else {
            if (unStakeCurrentStep === 5) {
                return;
            } else {
                setUnStakeCurrentStep(unStakeCurrentStep + 1);
            }
        }
    }

    const handleInputUnStake = (e) => {
        setIsMaxAmount(false);

        if (helpers.isFloatFormatted(e.target.value, 4)) {
            setUnStakeAmount(e.target.value);

            //check input
            if (BigNumber(e.target.value).lte(BigNumber(stakingWalletInfo["stakedAmount"])) && BigNumber(e.target.value).gt(BigNumber(0))) {
                setEnableUnStakeBtn(true);
            } else {
                setEnableUnStakeBtn(false);
            }
        }
    }

    const handleMaxButtonClick = () => {
        setUnStakeAmount(helpers.formatNumberDownRoundInput(stakingWalletInfo["stakedAmount"], 4));
        setEnableUnStakeBtn(true);
        setIsMaxAmount(true);
    }


    const submitDone = () => {
        setUnStakeAmount('');
        setIsMaxAmount(true);
        setUnStakeCurrentStep(1);
        setIsSubmitOK(false);
        setAcceptTerm(false);
        setLoading(0);
    }

    return (
        <StakingPortalPage selectedTab={2}>
            <div className="tab-pane fade show active pb-4" id="unstake" role="tabpanel" aria-labelledby="unstake-tab">
                <div className="row justify-content-center mb-4">
                    <div className="col-lg-12">
                        <div className="bs-stepper w-100">
                            <div className="bs-stepper-header" role="tablist">
                                {/* your steps here */}
                                <div className={`step ${unStakeCurrentStep >= 1 ? 'active' : ''}`} id="unStakeButtonStep1">
                                    <button type="button" className="step-trigger ps-0">
                                        <img src="/images/cir.png" />
                                        <span className="bs-stepper-label first">Warning</span>
                                    </button>
                                </div>
                                <div className={`line ${unStakeCurrentStep > 1 ? 'active' : ''}`} id="unStakeLineStep1" />
                                <div className={`step ${unStakeCurrentStep >= 2 ? 'active' : ''}`} id="unStakeButtonStep2">
                                    <button type="button" className="step-trigger">
                                        <img src="/images/cir.png" />
                                        <span className="bs-stepper-label">Checkpoints</span>
                                    </button>
                                </div>
                                <div className={`line ${unStakeCurrentStep > 2 ? 'active' : ''}`} id="unStakeLineStep2" />
                                <div className={`step ${unStakeCurrentStep >= 3 ? 'active' : ''}`} id="unStakeButtonStep3">
                                    <button type="button" className="step-trigger">
                                        <img src="/images/cir.png" />
                                        <span className="bs-stepper-label">Amount to Unstake</span>
                                    </button>
                                </div>
                                <div className={`line ${unStakeCurrentStep > 3 ? 'active' : ''}`} id="unStakeLineStep3" />
                                <div className={`step ${unStakeCurrentStep >= 4 ? 'active' : ''}`} id="unStakeButtonStep4">
                                    <button type="button" className="step-trigger">
                                        <img src="/images/cir.png" />
                                        <span className="bs-stepper-label">Initialize Unstake</span>
                                    </button>
                                </div>
                                <div className={`line ${unStakeCurrentStep > 4 ? 'active' : ''}`} id="unStakeLineStep4" />
                                <div className={`step ${unStakeCurrentStep >= 5 ? 'active' : ''}`} id="unStakeButtonStep5">
                                    <button type="button" className="step-trigger">
                                        <img src="/images/cir.png" />
                                        <span className="bs-stepper-label last">Confirmation</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-lg-12">
                        <div className="">
                            <div className="p-sidebar-card-body">
                                {
                                    unStakeCurrentStep === 1 &&
                                    <div className="bs-stepper-content" id="UnStakeStep1">
                                        <div className="text-center">
                                            <h4 className="mb-4 step-title">Warning</h4>
                                            <div className="step-warning d-flex justify-content-center align-items-center">
                                                <i className="fas fa-exclamation-triangle fa-2x text-warning me-3"></i>
                                                <div className="step-desc text-warning">{"After Unstaking, you must wait 7 days before you can withdraw your MAT and rewards."}</div>
                                            </div>
                                            <div className="step-desc text-warning">{"Note that during the unstake period, you don't receive any profit."}</div>
                                        </div>
                                    </div>
                                }

                                {
                                    unStakeCurrentStep === 2 &&
                                    <div className="bs-stepper-content" id="UnStakeStep2">
                                        <div className="text-center mb-5">
                                            <h4 className="mb-2 pb-1 step-title">Checkpoints</h4>
                                            <p className="step-desc">The following conditions must be met to proceed:</p>
                                        </div>

                                        <div className="grant__bordered bordered mb-0">
                                            <div className="bordered__inner bordered-statistics bordered--flex bordered--tripled bordered--cust bordered--double_angled bordered--bg_bottom">
                                                <div className={`bordered__block d-flex-1 ${account ? 'selected' : ''}`} >
                                                    <div className="p-sidebar-card-title">Connected MetaMask</div>
                                                    <div className="p-sidebar-card-body mt-2">
                                                        If not connected, click the "Connect Wallet" button in the top right corner
                                                    </div>
                                                </div>
                                                <div className={`bordered__block d-flex-1 ${parseFloat(stakingWalletInfo["stakedAmount"]) > 0 ? 'selected' : ''}`} >
                                                    <div className="p-sidebar-card-title">Have an active MAT stake</div>
                                                    <div className="p-sidebar-card-body mt-2">
                                                        You currently have {helpers.formatNumberDownRound(stakingWalletInfo["stakedAmount"], 2)} MAT staked
                                                    </div>
                                                </div>
                                                <div className={`bordered__block d-flex-1 ${bnbBalance > 0 ? 'selected' : ''}`} >
                                                    <div className="p-sidebar-card-title">BNB available</div>
                                                    <div className="p-sidebar-card-body mt-2">
                                                        BNB is required to pay transaction fees on the Binance Smart Chain network.<br />
                                                        BNB Balance: {helpers.formatNumberDownRoundWithExtractMax(bnbBalance, 4)}
                                                    </div>
                                                </div>
                                                <div className={`bordered__block d-flex-1 last ${stakingWalletInfo["withdrawTimestamp"] === 0 ? 'selected' : ''}`} >
                                                    <div className="p-sidebar-card-title">Eligible to stake</div>
                                                    <div className="p-sidebar-card-body mt-2">
                                                        You cannot stake if you have an active MAT unstake/withdrawal request
                                                    </div>
                                                </div>
                                                <div className="bordered__border bordered__border--left"></div>
                                                <div className="bordered__border bordered__border--right"></div>
                                            </div>
                                            <div className="bordered__icon bordered__icon--top_left bordered__icon--horizont bordered__icon--horizont_top">
                                                <img src="/images/bordered-lines.svg" alt="" />
                                            </div>
                                        </div>

                                        <div className="mt-4 mb-3 text-center">
                                            <div className="form-check">
                                                <input className="form-check-input float-none me-1"
                                                    type="checkbox" checked={acceptTerm} id="flexCheckDefault"
                                                    onChange={() => setAcceptTerm(prev => !prev)} />
                                                <label className="form-check-label" htmlFor="flexCheckDefault">
                                                    I have read the&nbsp;
                                                    <Link target="_blank" to={ROUTES.TERM_OF_USES}>Terms and Conditions </Link>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                }

                                {
                                    unStakeCurrentStep === 3 &&
                                    <div className="bs-stepper-content" id="UnStakeStep3">
                                        <div className="text-center">
                                            <h4 className="mb-2 pb-1 step-title">Amount to Unstake</h4>
                                            <p className="step-desc text-white">Please enter the amount of MAT you want to unstake</p>
                                            <p className="step-desc">Any fees applied at Unstake &amp; Withdraw stage will be based on the date you last staked.</p>
                                            <div className="unlock__bordered bordered mx-auto mt-5" style={{ maxWidth: '100%', width: '400px' }}>
                                                <div className="bordered__inner bordered--flex bordered--double_angled bordered--bg_right">
                                                    <div className="bordered__block pb-3">
                                                        <div className="text-start">
                                                            <div className="p-form-group">
                                                                <label className="form-label p-main-text">Amount</label>
                                                                <div className="btn btn--custom bordered bordered--btn bordered--btn_no-angled position-relative w-100 py-0">
                                                                    <div className="d-flex align-items-center justify-content-between px-3 w-100">
                                                                        <input autoFocus className="form-control px-0" placeholder={0.0} value={unStakeAmount} onChange={(e) => handleInputUnStake(e)} />
                                                                        <span style={{ cursor: 'pointer' }} onClick={handleMaxButtonClick}>MAX</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="bordered__border bordered__border--left"></div>
                                                    <div className="bordered__border bordered__border--right"></div>
                                                </div>
                                                <div className="bordered__icon bordered__icon--top_left bordered__icon--horizont bordered__icon--horizont_top">
                                                    <img src="/images/bordered-lines.svg" alt="" />
                                                </div>
                                                <div className="bordered__icon bordered__icon--bottom_center bordered__icon--horizont bordered__icon--horizont_bottom">
                                                    <img src="/images/bordered-lines.svg" alt="" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }

                                {
                                    unStakeCurrentStep === 4 &&
                                    <div className="bs-stepper-content" id="UnStakeStep4">
                                        <div className="text-center">
                                            <h4 className="mb-4 step-title">Confirm Unstaking Process</h4>
                                            <p className="step-desc">In this step, you initiate the unstaking process.</p>
                                        </div>
                                    </div>
                                }

                                {
                                    unStakeCurrentStep === 5 &&
                                    <div className="bs-stepper-content" id="UnStakeStep5">
                                        <div className="text-center">
                                            <div className="icon-gradient">
                                                <i className="mdi mdi-shield-check-outline" style={{ fontSize: '70px', lineHeight: 1.3 }} />
                                            </div>
                                            <h4 className="step-title">Confirmed</h4>
                                            <p className="step-desc">
                                                You have initiated the unstaking process.<br />
                                                If desired, you may check Binance SmartChain to confirm the transaction.
                                            </p>
                                            <p><a className="p-address" href={`${BSC_EXPLORER[chainId]}/tx/${transactionHash}`} target="_blank">{transactionHash}</a></p>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mb-3 mt-4 text-center step-buttons">
                    {!isSubmitOK ?
                        <div className="d-flex align-items-center justify-content-center" style={{ gap: '20px' }}>
                            <button type="button"
                                onClick={() => unStakeBack()} type="button"
                                // disabled={!enableUnStakeBtn || unStakeCurrentStep === 1}
                                disabled={unStakeCurrentStep === 1}
                                className="tq_btn tq_btn_sm tq_btn_outline tq_btn_outline_primary px-4 px-md-5">
                                <span className="tq_btn_text">
                                    <i className="mdi mdi-arrow-left me-2"></i>
                                    <span>Previous</span>
                                </span>
                                <div className="tq_btn_hover"></div>
                            </button>

                            <button type="button"
                                onClick={() => unStakeNext()} type="button"
                                disabled={!enableUnStakeBtn || unStakeCurrentStep === 5}
                                className="tq_btn tq_btn_sm tq_btn_outline tq_btn_outline_primary tq_btn_primary px-4 px-md-5">
                                <span className="tq_btn_text">
                                    <span>Next</span>
                                    <i className="mdi mdi-arrow-right ms-2"></i>
                                </span>
                                <div className="tq_btn_hover"></div>
                            </button>
                        </div>
                        :
                        <div className="d-flex align-items-center justify-content-center">
                            <button type="button"
                                onClick={() => submitDone()}
                                className="tq_btn tq_btn_sm tq_btn_outline tq_btn_outline_primary tq_btn_primary px-4 px-md-5">
                                <span className="tq_btn_text">
                                    <span>Done</span>
                                </span>
                                <div className="tq_btn_hover"></div>
                            </button>
                        </div>
                    }
                </div>
            </div>
            <ConfirmationModal
                loading={loading}
                visible={visible}
                type={STAKE_ACTION.UNSTACKING}
                onCancel={() => {
                    setVisible(false);
                    setLoading(0);
                }}
            />
        </StakingPortalPage>
    );
};

export default UnStakingPage;
