export const ACTION_CONST = {
    LANGUAGE_CHANGE: 'LANGUAGE_CHANGE',
    REQUEST_SUBMIT:'REQUEST_SUBMIT',
    REQUEST_DONE:'REQUEST_DONE',
    ALERT_SUCCESS: 'ALERT_SUCCESS',
    ALERT_FAILS: 'ALERT_FAILS',
    ALERT_WARNING: 'ALERT_WARNING',
    ALERT_CLEAR: 'ALERT_CLEAR',
    ALERT_LINK: 'ALERT_LINK',
    ALERT_LINK_CLEAR: 'ALERT_LINK_CLEAR',

    CONNECT_WALLET_SUCCESS:'CONNECT_WALLET_SUCCESS',
    LOG_OUT_WALLET_SUCCESS :'LOG_OUT_WALLET_SUCCESS',
    ENABLE_WALLET_SUCCESS: 'ENABLE_WALLET_SUCCESS',

    SET_SHOW_MODAL_HELP:'SET_SHOW_MODAL_HELP',
    

  
    TAB_SELECTED_CHANGE: 'TAB_SELECTED_CHANGE',

    GET_MAT_WALLET_BALANCE:"GET_MAT_WALLET_BALANCE",

    
    SET_LIST_CONTRACT_SELECTED: 'SET_LIST_CONTRACT_SELECTED',


    GET_INFO_WALLET: 'GET_INFO_WALLET',


    //merge staking

    GET_STAKING_INFO:'GET_STAKING_INFO',
    SET_JOB_GET_STAKING_INFO: 'SET_GET_STAKING_INFO',
    GET_STAKING_WALLET_INFO:'GET_STAKING_WALLET_INFO',
    SET_JOB_GET_STAKING_WALLET_INFO: 'SET_GET_STAKING_WALLET_INFO',

    SET_JOB_COUNTDOWN_STAKE_TIME: 'SET_JOB_COUNTDOWN_STAKE_TIME',

    SUBMIT_GET_BALANCE: 'SUBMIT_GET_BALANCE',
    GET_TOKENPAD_BALANCE: 'GET_TOKENPAD_BALANCE',
    GET_BNB_BALANCE:'GET_BNB_BALANCE', 
    SET_JOB_GET_BALANCE:'SET_JOB_GET_BALANCE',


    CLEAR_STAKING_JOB:'CLEAR_STAKING_JOB',

    SET_STAKING_ENABLE:'SET_STAKING_ENABLE',
    SET_JOB_GET_STAKING_STATUS:'SET_JOB_GET_STAKING_STATUS',

    SET_LATEST_BLOCK:"SET_LATEST_BLOCK",

}
