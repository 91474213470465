import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Modal } from 'react-bootstrap';
import { MAPPING_STAKE_ACTION, STAKE_ACTION } from '../../constants';
import { BSC_EXPLORER } from '../../_configs';
import { useActiveWeb3React } from '../../hook';

const ConfirmationModal = ({ loading, approving, visible, txID = '', type, onCancel = () => { } }) => {

    const { chainId } = useActiveWeb3React();

    const handleDirect = () => {
        window.open(`${BSC_EXPLORER[chainId]}/tx/${txID}`);
    }

    return (
        <Modal
            centered={true}
            show={visible}
            onHide={onCancel}
            className='tq_modal'
            backdrop="static"
        >
            <Modal.Header closeButton={loading === -1 ? true : false}></Modal.Header>
            <Modal.Body>
                <div className="text-center">
                    <h2 id="transition-modal-title">{loading === 0 ? (approving ? 'Approving' : MAPPING_STAKE_ACTION[type]) : loading === -1 ? 'Error' : 'Done'}</h2>
                    <p id="transition-modal-description">{
                        loading === 0 ? 'Your request is being processed. Please wait...' :
                            loading === 1 ?
                                'Your request is submitted. View detail on BSCScan.'
                                :
                                'Your request is rejected. Please try again later.'
                    }</p>
                    {loading === 0 ?
                        <CircularProgress />
                        :
                        loading === 1 ?
                            <div>
                                <div><i className="fas fa-check text-success" style={{ fontSize: '60px' }}></i></div>
                            </div>
                            :
                            <div>
                                <div><i className="fas fa-exclamation-triangle text-warning" style={{ fontSize: '60px' }}></i></div>
                            </div>
                    }
                    {loading === 1 &&
                        <div onClick={handleDirect}><u>View on BscScan</u></div>
                    }
                    {loading !== 0 &&
                        <div className="mt-4">
                            <button onClick={onCancel} className="tq_btn tq_btn_sm tq_btn_outline tq_btn_outline_primary px-4 mx-auto">Close</button>
                        </div>
                    }

                </div>
            </Modal.Body>
        </Modal>
    )
}

export default ConfirmationModal
