import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import exactMath from 'exact-math';
import { ACTION_CONST, ROUTES } from "../../constants";
import { helpers } from "../../shared/utils";
import $ from 'jquery';


import { useActiveWeb3React } from "../../hook";
import { useLatestBlockNumber, usePadTokenBalance, useSubmit, useWeb3Utils } from "../../hook/useState";



const Header = () => {
  const dispatch = useDispatch();
  const { account, library, chainId } = useActiveWeb3React()
  const walletUtils = useWeb3Utils()
  const matBalance = usePadTokenBalance();
  const submitOK = useSubmit();

  const latestBlock = useLatestBlockNumber()

  //set balance
  useEffect(() => {

    if (walletUtils && account) {

      //get bnb balance
      walletUtils.web3.eth.getBalance(account).then(balance => {

        dispatch({
          type: ACTION_CONST.GET_BNB_BALANCE,
          data: exactMath.div(balance, 10 ** 18)
        })
      }).catch(err => {
        console.log(err);
      })

      walletUtils.getTokenPadBalance().then(data => {

        dispatch({
          type: ACTION_CONST.GET_MAT_WALLET_BALANCE,
          data: data
        })
      })

      //get staking wallet Info
      walletUtils.getStakingInfoWallet().then(data => {
        dispatch({ type: ACTION_CONST.GET_STAKING_WALLET_INFO, data: data })
      });


      //Set interval

    }

  }, [latestBlock, walletUtils, account, submitOK]);





  const handleTabChange = () => {
    $('.navbar-toggler').trigger('click');
  }

  return (
    <>
      <nav id="PPNavbar" className="navbar navbar-expand-lg navbar-dark">
        <div className="container-fluid">
          <a className="navbar-brand d-flex align-items-center" href="https://mymasterwar.com/">
            <img src="/images/logo.png" height="41" alt="MyMasterWar" />
          </a>
          <div className="d-flex align-items-center" style={{ flexWrap: 'nowrap' }}>
            <div className="dropdown d-block d-md-none d-lg-none d-xl-none">


            </div>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
          </div>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-3 mt-3 mt-lg-0 mb-lg-0">
              <li className="nav-item">
                <a className="nav-link" href="https://mymasterwar.com/" target="_blank">
                  <span>Home</span>
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="https://marketplace.mymasterwar.com" target="_blank">
                  <span>Market</span>
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="https://docs.mymasterwar.com/game-play/mat-staking" target="_blank">
                  <span>Guide</span>
                </a>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" activeClassName="active" to={ROUTES.STACKING} onClick={handleTabChange}>
                  <span>Stake</span>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" activeClassName="active" to={ROUTES.UNSTACKING} onClick={handleTabChange}>
                  <span>Unstake</span>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" activeClassName="active" to={ROUTES.WITHDRAW} onClick={handleTabChange}>
                  <span>Withdraw</span>
                </NavLink>
              </li>
            </ul>
            <ul className={account ? 'navbar-nav ms-auto mb-2 mb-md-0 connected' : 'navbar-nav ms-auto mb-2 mb-md-0'}>
              <li className="nav-item me-2 d-none d-md-block">
                <a href="https://play.mymasterwar.com/" target="_blank"
                  className="tq_btn tq_btn_outline tq_btn_outline_primary text-decoration-none">
                  <span className="tq_btn_text">
                    Play game
                  </span>
                  <div className="tq_btn_hover"></div>
                </a>
              </li>
              {
                !account ?
                  <li className="nav-item me-2">
                    <button type="button"
                      data-bs-toggle="modal" data-bs-target={"#connectWalletModal"}
                      className="tq_btn tq_btn_outline tq_btn_outline_primary ms-2">
                      <span className="tq_btn_text">
                        Connect Wallet
                      </span>
                      <div className="tq_btn_hover"></div>
                    </button>
                  </li>
                  :

                  <li className="nav-item me-2">
                    <button type="button"
                      data-bs-toggle="modal" data-bs-target={"#walletModal"}
                      className="tq_btn tq_btn_outline tq_btn_outline_primary ms-2">
                      <span className="tq_btn_text">
                        <b>{helpers.formatTransactionHash(account, 4, 4)}</b> - {helpers.formatNumberDownRoundWithExtractMax(matBalance, 4)} MAT
                      </span>
                      <div className="tq_btn_hover"></div>
                    </button>
                  </li>


              }
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Header;
