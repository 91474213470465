import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import $ from 'jquery';
import { get } from "lodash";
import { Link } from "react-router-dom";
import { BSC_EXPLORER, MODE } from "../../_configs";
import { ACTION_CONST, ROUTES, STAKE_ACTION, STATUS } from "../../constants";
import { helpers } from "../../shared/utils";
import StakingPortalPage from "../../shared/layout/StakingPortal";
import { useBnbBalance, useStakingInfo, useWeb3Utils } from "../../hook/useState";
import { useStakingWalletInfo } from "../../hook/useState";
import { useActiveWeb3React } from "../../hook";
import ConfirmationModal from "../../components/ConfirmationModal";

const WithDrawPage = () => {
    const { account, chainId, library } = useActiveWeb3React();
    const dispatch = useDispatch();
    const [withdrawCurrentStep, setWithdrawCurrentStep] = useState(1);
    const walletUtils = useWeb3Utils();

    const stakingInfo = useStakingInfo();
    const stakingWalletInfo = useStakingWalletInfo();
    const [acceptTerm, setAcceptTerm] = useState(false);
    const [enableWithDrawBtn, setEnableWithDrawBBtn] = useState(false);
    const [transactionHash, setTransactionHash] = useState("");
    const bnbBalance = useBnbBalance()
    const [isSubmitOK, setIsSubmitOK] = useState(false);

    const [loading, setLoading] = useState(0);
    const [visible, setVisible] = useState(false);

    /* WITHDRAW */
    useEffect(() => {
        setWithdrawStepActive();
    }, [setWithdrawStepActive, withdrawCurrentStep])




    //enable withdraw button
    useEffect(() => {
     
        if (acceptTerm && account && bnbBalance > 0 && !stakingInfo["isStakingPaused"] && stakingWalletInfo["withdrawTimestamp"] > 0 && stakingWalletInfo["withdrawTimestamp"] <= (new Date().getTime())) {
            setEnableWithDrawBBtn(true);
        } else {
            setEnableWithDrawBBtn(false);
        }
    }, [acceptTerm, account, bnbBalance, stakingInfo, stakingWalletInfo])


    const withdrawBack = () => {
        if (withdrawCurrentStep == 1) {
            return;
        } else {
            setWithdrawCurrentStep(withdrawCurrentStep - 1);
        }
    }

    const withdrawNext = () => {

        if (withdrawCurrentStep === 2) {
            if (walletUtils) {
                setVisible(true);
                dispatch({
                    type: ACTION_CONST.REQUEST_SUBMIT
                })
                walletUtils.stakingExecuteWithdrawal(data => {
                    if (data.status === STATUS.STAKING_EXECUTE_WITHDRAWAL_SUCCESS) {
                        setLoading(1);
                        setVisible(false);
                        dispatch({
                            type: ACTION_CONST.REQUEST_DONE
                        })
                        setIsSubmitOK(true)
                        setTransactionHash(data.txID)
                        setWithdrawCurrentStep(3);
                    }
                    if (data.status === STATUS.STAKING_EXECUTE_WITHDRAWAL_FAIL) {
                        setLoading(-1);
                        // setVisible(false);
                        dispatch({
                            type: ACTION_CONST.REQUEST_DONE
                        })

                        dispatch({
                            type: ACTION_CONST.ALERT_FAILS,
                            message: "Withdraw stake fail"
                        })
                    }
                })
            }
        } else {
            if (withdrawCurrentStep == 3) {
                return;
            } else {
                setWithdrawCurrentStep(withdrawCurrentStep + 1);
            }
        }
    }


    const submitDone = () => {
        setWithdrawCurrentStep(1);
        setAcceptTerm(false);
        setIsSubmitOK(false);
        setLoading(0);
    }


    function setWithdrawStepActive() {
        $('#withdraw .bs-stepper-header .step').removeClass('active');
        $('#withdraw .bs-stepper-header .line').removeClass('active');
        for (var i = 1; i <= 3; i++) {
            if (i <= withdrawCurrentStep) {
                $('#withdraw #withdrawButtonStep' + i).addClass('active');
                $('#withdraw #withdrawLineStep' + (i - 1)).addClass('active');
            }
        }
        $('#withdraw .bs-stepper-content').hide();
        $('#withdraw #WithdrawStep' + withdrawCurrentStep).show();
    }

    return (
        <StakingPortalPage selectedTab={3}>
            <div className="tab-pane fade show active" id="withdraw" role="tabpanel" aria-labelledby="withdraw-tab">
                <div className="row justify-content-center mb-4">
                    <div className="col-lg-12">
                        <div className="bs-stepper w-100">
                            <div className="bs-stepper-header" role="tablist">
                                <div className="step active" id="withdrawButtonStep1">
                                    <button type="button" className="step-trigger ps-0">
                                        <img src="/images/cir.png" />
                                        <span className="bs-stepper-label first">Checkpoints</span>
                                    </button>
                                </div>
                                <div className="line" id="withdrawLineStep1" />
                                <div className="step" id="withdrawButtonStep2">
                                    <button type="button" className="step-trigger">
                                        <img src="/images/cir.png" />
                                        <span className="bs-stepper-label">Initialize Withdrawal</span>
                                    </button>
                                </div>
                                <div className="line" id="withdrawLineStep2" />
                                <div className="step" id="withdrawButtonStep3">
                                    <button type="button" className="step-trigger">
                                        <img src="/images/cir.png" />
                                        <span className="bs-stepper-label last">Confirmation</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-lg-12">
                        <div className="">
                            <div className="p-sidebar-card-body">
                                <div className="bs-stepper-content" id="WithdrawStep1">
                                    <div className="text-center mb-5">
                                        <h4 className="mb-2 pb-1 step-title">Checkpoints</h4>
                                        <p className="step-desc">The following conditions must be met to proceed:</p>
                                    </div>

                                    <div className="grant__bordered bordered mb-0">
                                        <div className="bordered__inner bordered-statistics bordered--flex bordered--tripled bordered--cust bordered--double_angled bordered--bg_bottom">
                                            <div className={`bordered__block d-flex-1 ${account ? 'selected' : ''}`} >
                                                <div className="p-sidebar-card-title">Connected MetaMask</div>
                                                <div className="p-sidebar-card-body mt-2">
                                                    If not connected, click the "Connect Wallet" button in the top right corner
                                                </div>
                                            </div>

                                            <div className={`bordered__block d-flex-1 ${stakingWalletInfo["withdrawTimestamp"] > 0 && stakingWalletInfo["withdrawTimestamp"] <= (new Date().getTime()) ? 'selected' : ''}`} >
                                                <div className="p-sidebar-card-title">7 day waiting period elapsed</div>
                                                <div className="p-sidebar-card-body mt-2">
                                                </div>
                                            </div>

                                            <div className={`bordered__block d-flex-1 ${bnbBalance > 0 ? 'selected' : ''}`} >
                                                <div className="p-sidebar-card-title">BNB available</div>
                                                <div className="p-sidebar-card-body mt-2">
                                                    BNB is required to pay transaction fees on the Binance Smart Chain network.<br />
                                                    BNB Balance: {helpers.formatNumberDownRoundWithExtractMax(bnbBalance, 4)}
                                                </div>
                                            </div>

                                            <div className={`bordered__block d-flex-1 ${stakingWalletInfo["withdrawTimestamp"] > 0 ? 'selected' : ''}`} >
                                                <div className="p-sidebar-card-title">You have Unstaked your MAT</div>
                                                <div className="p-sidebar-card-body mt-2">
                                                </div>
                                            </div>
                                            <div className="bordered__border bordered__border--left"></div>
                                            <div className="bordered__border bordered__border--right"></div>
                                        </div>
                                        <div className="bordered__icon bordered__icon--top_left bordered__icon--horizont bordered__icon--horizont_top">
                                            <img src="/images/bordered-lines.svg" alt="" />
                                        </div>
                                    </div>

                                    <div className="mt-4 mb-3 text-center">
                                        <div className="form-check">
                                            <input className="form-check-input float-none me-1"
                                                type="checkbox" checked={acceptTerm} id="flexCheckDefault"
                                                onChange={() => setAcceptTerm(prev => !prev)} />
                                            <label className="form-check-label" htmlFor="flexCheckDefault">
                                                I have read the&nbsp;
                                                <Link target="_blank" to={ROUTES.TERM_OF_USES}>Terms and Conditions </Link>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="bs-stepper-content" id="WithdrawStep2">
                                    <div className="text-center">
                                        <h4 className="mb-2 pb-1 step-title">Confirm Withdrawal</h4>
                                        <p className="step-desc">In this step, you complete the transaction that withdraws your MAT tokens</p>
                                    </div>
                                </div>
                                <div className="bs-stepper-content" id="WithdrawStep3">
                                    <div className="text-center">
                                        <div className="text-white">
                                            <i className="mdi mdi-shield-check-outline" style={{ fontSize: '70px', lineHeight: 1.3 }} />
                                        </div>
                                        <h4 className="mb-2 pb-1 step-title">Confirmed</h4>
                                        <p className="step-desc">
                                            You have withdrawn your MAT tokens.<br />
                                            If desired, you may check Binance Smart Chain to confirm the transaction.
                                        </p>
                                        <p><a className="p-address" href={`${BSC_EXPLORER[chainId]}/tx/${transactionHash}`} target="_blank">{transactionHash}</a></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="mb-3 mt-4 text-center step-buttons">
                    {
                        !isSubmitOK ?
                            <div className="d-flex align-items-center justify-content-center" style={{ gap: '20px' }}>
                                <button
                                    onClick={() => withdrawBack()} type="button"
                                    disabled={!enableWithDrawBtn || withdrawCurrentStep === 1}
                                    className="tq_btn tq_btn_sm tq_btn_outline tq_btn_outline_primary px-4 px-md-5">
                                   <span className="tq_btn_text">
                                        <i className="mdi mdi-arrow-left me-2"></i>
                                        <span>Previous</span>
                                    </span>
                                    <div className="tq_btn_hover"></div>

                                </button>
                                <button
                                    onClick={() => withdrawNext()} type="button"
                                    disabled={!enableWithDrawBtn || withdrawCurrentStep === 3}
                                    className="tq_btn tq_btn_sm tq_btn_outline tq_btn_outline_primary tq_btn_primary px-4 px-md-5">
                                    <span className="tq_btn_text">
                                        <span>Next</span>
                                        <i className="mdi mdi-arrow-right ms-2"></i>
                                    </span>

                                </button>
                            </div>
                            :
                            <div className="d-flex align-items-center justify-content-center">
                                <button
                                    onClick={() => submitDone()} type="button"
                                    className="tq_btn tq_btn_sm tq_btn_outline tq_btn_outline_primary tq_btn_primary px-4 px-md-5">
                                    <span className="tq_btn_text">
                                        <span>Done</span>
                                    </span>
                                    <div className="tq_btn_hover"></div>

                                </button>
                            </div>
                    }
                </div>
            </div>
            <ConfirmationModal
                visible={visible}
                loading={loading}
                type={STAKE_ACTION.WITHDRAW}
                onCancel={() => {
                    setVisible(false);
                    setLoading(0);
                }}
            />
        </StakingPortalPage>
    );
};

export default WithDrawPage;
