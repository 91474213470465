import React from "react";
import { Router, Switch, Redirect } from "react-router-dom";
import { history } from "./shared/utils/history";
import { PrivateRoute } from "./shared/routes/PrivateRoute";
import { ROUTES } from "./constants";
import PrivacyPolicyPage from "./pages/privacy-policy";
import CookiesPolicyPage from "./pages/cookies-policy";
import TermOfUsePage from "./pages/terms-conditions";
import StakingPage from "./pages/staking";
import UnStakingPage from "./pages/unstaking";
import Web3ReactManager from "./components/Web3ReactManager";
import AppUpdater from "./hook/updater";
import WithDrawPage from "./pages/withdraw";

const App = () => {
  return (
    <>
      <Router history={history}>
        <Web3ReactManager>
        <AppUpdater />
          <Switch>
            <PrivateRoute
              exact
              path={ROUTES.STACKING}
              component={StakingPage}
            />
            <PrivateRoute
              exact
              path={ROUTES.UNSTACKING}
              component={UnStakingPage}
            />
             <PrivateRoute
              exact
              path={ROUTES.WITHDRAW}
              component={WithDrawPage}
            />
            <PrivateRoute
              exact
              path={ROUTES.PRIVACY_POLICY}
              component={PrivacyPolicyPage}
            />
            <PrivateRoute
              exact
              path={ROUTES.COOKIES_POLICY}
              component={CookiesPolicyPage}
            />
            <PrivateRoute
              exact
              path={ROUTES.TERM_OF_USES}
              component={TermOfUsePage}
            />

            <Redirect from='**' to={ROUTES.STACKING} />
          </Switch>
        </Web3ReactManager>
      </Router>
    </>
  );
};

export default App;
