export const MODE = process.env.REACT_APP_MODE || "TESTNET";

export const BSC_EXPLORER = {
  56: "https://bscscan.com",
  97: "https://testnet.bscscan.com",
};

export const STAKING_CONTRACT_ADDRESS = {
  56: "0xd6a31ad3b0e81cf7c5c285fdaf9d039134a0f6c4",
  97: "0x6DfBf6144Bb1fDf34871939B5D95EaA73f7532e0",
};

export const BSC_MAT_ADDRESS = {
  56: "0xf3147987a00d35eecc10c731269003ca093740ca",
  97: "0xe8BA4c94713bA1B5f206767885415304942e783b",
};

export const POOL_INTERVAL = 15000;

export const BACKEND_URI = "https://staking-gamezone.myfantasy.cloud";

// export const BACKEND_URI = 'https://app.gamezone.io'

export const NODE_URI = {
  56: [
    "https://bsc-dataseed.binance.org/",
    "https://bsc-dataseed1.defibit.io/",
    "https://bsc-dataseed1.ninicoin.io/",
  ],
  97: ["https://data-seed-prebsc-1-s2.binance.org:8545/"],
  // 1: ['https://mainnet.infura.io/v3/e6d8a9c75b2e4e8c9d1e4c57446ffded']
};

export const KYC_BACK_END_URI = "https://bscpad.com";

export const chainIdsSupport = [56];

// export const defaultChainId = 97
export const defaultChainId = process.env.REACT_APP_MODE === "MAINNET" ? 56 : 97;

export const showKYC = 1000.0;

export const COUNTDOWN_TIME = 1636207200000;
