import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { useActiveWeb3React } from '../../hook';
import useAuth from '../../hook/useAuth';
import Web3Helper from '../utils/walletExtensionUtils';
import { connectorLocalStorageKey } from '../../components/literals';
import { connectors, ACTION_CONST } from '../../constants';

const ConnectWalletModal = () => {
    const dispatch = useDispatch();
    const { login } = useAuth();
    const { account, library, error, chainId } = useActiveWeb3React()

    const handleConnectClick = useCallback((walletConfig) => {
        try {
            login(walletConfig.connectorId);
            window.localStorage.setItem(connectorLocalStorageKey, walletConfig.connectorId);
        } catch (error) {
            console.log(error);
        }
    }, [])

    useEffect(() => {
        if (error) return;
        if (account && library && library.provider) {
            const web3 = new Web3Helper(library.provider, account, chainId)
            dispatch({
                type: ACTION_CONST.ENABLE_WALLET_SUCCESS,
                data: web3
            })
            return;
        }
    }, [library, account, error])

    return (
        <>
            <div className="modal fade tq_modal" id="connectWalletModal" tabIndex="-1" aria-labelledby="connectWalletModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-sm">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="connectWalletModalLabel">Connect to wallet</h5>
                        </div>
                        <div className="modal-body p-4 pb-2">
                            {connectors.map((entry, key) => {
                                if (entry.enable === true) {
                                    return (
                                        <button type="button" className={`tq_btn tq_btn_outline tq_btn_outline_primary mx-auto my-3 py-3 px-4 ${key === 0 ? 'tq_btn_primary' : ''} mb-4`} key={key} data-bs-dismiss="modal" onClick={() =>
                                            handleConnectClick(entry)
                                        }>
                                            <span className="tq_btn_text">
                                                <img className="btn__icon" src={entry.icon} height="26" className="me-2" alt={entry.connectorId} />
                                                <span>{entry.title}</span>
                                            </span>
                                            <div className="tq_btn_hover"></div>
                                        </button>
                                    )
                                }
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ConnectWalletModal;


